@charset 'UTF-8';

.lineup {
  &__wrapper {
    width: 100%;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
  }
  .top__cmbtn {
    max-width: 260px;
    width: 100%;
    margin: 24px auto;
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .top__blockhd {
    letter-spacing: 0.5px;
    @include SP {
      font-size: 21px;
    }
  }
  .point-event {
    pointer-events: none;
    display: none;
    @include SP {
      pointer-events: inherit;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .left-thumb {
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  &__top {
    max-width: 1040px;
    width: 100%;
    margin: 45px auto 0;
    @include SP {
      margin-top: 18px;
    }
    &--subtxt {
      text-align: center;
      font-size: 16px;
      margin: 21px 0;
      line-height: 30px;
      @include ipad {
        font-size: 14px;
      }
      @include SP {
        margin-top: 14px;
        font-size: 15px;
        text-align: left;
        letter-spacing: -0.8px;
        line-height: 25px;
        padding-left: 15px;
        padding-right: 15px;
      }
      @include IP5 {
        font-size: 12px;
      }
    }
    &--inner {
      margin: 113px auto 63px;

      @include SP {
        margin-top: 48px;
        margin-bottom: 70px;
      }
    }
    &--content {
      display: flex;
      position: relative;
      margin: 0 0 50px;
      @include SP {
        flex-wrap: wrap;
        margin-bottom: 44px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &::after {
        @include PC {
          content: "";
          position: absolute;
          top: 140px;
          width: 91%;
          height: 71.05%;
          background: #f1f1f1;
          z-index: -1;
        }
        @include ipad {
          bottom: 10px;
        }
        @include SP {
          content: "";
          position: absolute;
          bottom: 0;
          background: #f1f1f1;
          z-index: -1;
          width: 81%;
          height: 68%;
        }
      }
      @include PC {
        &:nth-child(2) {
          margin-bottom: 55px;
        }
        &:nth-child(3) {
          margin-bottom: 65px;
        }
      }
      &:nth-child(odd) {
        &::after {
          right: 0;
        }
        @include SP {
          .img {
            padding-right: 15px;
          }
        }
      }
      &:nth-child(even) {
        &::after {
          left: 0;
        }
        .txt {
          order: 1;
          @include SP {
            order: 2;
          }
        }
        .img {
          order: 2;
          @include SP {
            padding-left: 15px;
            order: 1;
          }
        }
      }
      // @include PC {
      //   img {
      //     border: 1px solid #ccc;
      //     width: 500px;
      //     height: 334px;
      //   }
      // }
      .img {
        max-width: 500px;
        width: 100%;
        margin: 7px 0 141px;
        // img {
        //   border: 1px solid #000;
        // }
        @media (min-width: 768px) and(max-width: 920px) {
          max-width: 350px;
          img {
            width: auto;
            height: auto;
          }
        }
        @include SP {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
      .txt {
        max-width: calc(100% - 500px);
        width: 100%;
        padding: 0 0 0 50px;
        @include PC {
          padding-bottom: 90px;
        }
        @media (min-width: 768px) and(max-width: 920px) {
          max-width: calc(100% - 350px);
          padding: 0 0 90px 20px;
          .content {
            margin-top: 60px;
          }
        }
        @include SP {
          max-width: 100%;
          padding: 0;
        }
      }
      .cat {
        font-size: 20px;
        position: relative;
        letter-spacing: 1px;
        font-weight: bold;
        margin: 0 0 12px;
        @include PC {
          padding: 0 0 12px;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 58px;
            height: 3px;
            background: #000;
          }
        }
        @include SP {
          text-align: center;
          font-size: 15px;
          margin: 20px 0 0;
        }
      }
      .title {
        font-size: 40px;
        font-weight: bold;
        line-height: 30px;
        margin: 26px 0;
        letter-spacing: 3px;
        @include SP {
          font-size: 28px;
          text-align: center;
          line-height: 20px;
          margin: 3px;
        }
      }
      .content {
        margin: 76px 35px 26px 0;
        @include ipad {
          margin: 76px 25px 26px 0;
        }
        @include SP {
          margin: 18px auto 29px;
          padding: 0 15px;
          line-height: 19px;
          letter-spacing: 0.5px;
        }
        p {
          font-size: 14px;
          @include IP5 {
            font-size: 12px;
          }
        }
      }
      .top__cmbtn {
        max-width: 260px;
        width: 100%;
        margin: 0;
        display: block;
        background: transparent;
        @include SP {
          display: none;
        }
      }
    }
  }
  &__single {
    &--content {
      text-align: center;
    }
    &--main {
      text-align: center;
      max-width: 690px;
      width: 100%;
      margin: 46px auto;
      @include SP {
        margin: 19px auto 25px;
        max-width: 100% !important;
        width: 100%;
        height: 320px;
        overflow: hidden;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          margin: 0 auto;
          width: 100%;
        }
      }
      @media (min-width: 320px) and (max-width: 330px) {
        width: 100%;
        height: 160px;
        max-width: 640px;
      }
    }
    &--top {
      @include ipad {
        padding: 0 20px;
      }
      @include SP {
        padding: 0 16px;
      }
      .cat {
        font-size: 20px;
        font-weight: bold;
        padding: 0 0 9px;
        position: relative;
        display: inline-block;
        letter-spacing: 2px;
        @include SP {
          font-size: 15px;
          letter-spacing: 1px;
          line-height: 20px;
          padding: 0;
        }
        &::after {
          content: "";
          position: absolute;
          width: 73px;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background: #000;
          margin: 0 auto;
          @include SP {
            display: none;
          }
        }
      }
      .title {
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 2px;
        @include SP {
          font-size: 28px;
          line-height: 32px;
        }
      }
      .heading {
        font-size: 22px;
        font-weight: bold;
        margin: 36px 0 14px;
        @include SP {
          font-size: 18px;
          font-weight: bold;
          margin: 31px 0 13px;
          text-align: left;
        }
      }
      .description {
        font-size: 16px;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        letter-spacing: -0.55px;
        text-align: left;
        line-height: 26px;
        @include SP {
          letter-spacing: -2px;
          line-height: 25px;
        }
      }
      .price {
        font-size: 20px;
        font-weight: bold;
        position: relative;
        display: inline-flex;
        margin: 86px 0 70px;
        letter-spacing: 1px;
        align-items: center;
        line-height: 61px;
        padding: 0 12px 8px;
        @include SP {
          margin: 18px 0 12px;
          font-size: 16px;
          padding: 0;
          width: 88%;
          text-align: center;
          display: inline-flex;
          justify-content: center;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 3px;
          background: #000;
          margin: 0 auto;
        }
        i {
          font-style: normal;
          @include PC {
            position: relative;
            top: 2px;
          }
        }
        span {
          font-size: 42px;
          font-weight: bold;
          letter-spacing: 2px;
          margin: 0 10px 0 27px;
          @include SP {
            font-size: 33px;
            margin: 0 0 0 19px;
            letter-spacing: 2px;
          }
          @include IP5 {
            font-size: 30px;
          }
        }
        em {
          font-size: 16px;
          position: relative;
          top: 9px;
          @include SP {
            font-size: 13px;
            top: 7px;
            left: 7px;
          }
        }
      }
    }
    &--recomend {
      background: #f1f1f1;
      padding: 0 0 50px;
      margin: 50px auto 0;
      @include SP {
        padding: 0 0 19px;
      }
      > div {
        max-width: 818px;
        width: 100%;
        margin: 0 auto;
        @include SP {
          padding: 0 15px;
        }
      }
      .top__blockhd {
        background: url(../img/lineup/ico_hand.png) no-repeat top center;
        background-size: 29px 45px;
        padding: 72px 0 0 0;
        position: relative;
        top: -25px;
        letter-spacing: -1px;
        @include SP {
          background-size: 26px 38px;
          padding: 51px 0 0 0;
          top: -18px;
          letter-spacing: 1px;
        }
      }
      .check {
        @include PC {
          position: relative;
          right: 16px;
        }
        @include ipad {
          right: 0;
          padding: 0 20px;
        }
        &__text {
          position: relative;
          padding: 0 0 0 35px;
          margin: 0 0 23px;
          letter-spacing: 0.8px;
          @include SP {
            font-size: 14px;
            padding: 0 0 0 29px;
            line-height: 19px;
            margin: 0 0 10px;
            letter-spacing: -1px;
          }
          @include IP5 {
            font-size: 13px;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: url(../img/lineup/ico_check.png) no-repeat center left;
            background-size: contain;
            width: 21px;
            height: 22px;
            @include SP {
              top: -5px;
              width: 19px;
              height: 22px;
            }
          }
        }
      }
    } // -------------------------------------------------------
    &--list {
      margin: 98px auto 0;
      @include SP {
        margin: 50px auto 0;
      }
      .top__blockhd {
        margin: 0 auto 82px;
        font-size: 30px;
        letter-spacing: 3px;
        @include SP {
          font-size: 21px;
          letter-spacing: 2px;
          margin-bottom: 41px;
        }
      }
      .title {
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 20px;
        @include ipad {
          font-size: 20px;
        }
        @include SP {
          font-size: 18px;
          letter-spacing: 0.2px;
          margin: 0 0 13px;
        }
      }
      .text {
        font-size: 16px;
        text-align: left;
        line-height: 24px;
        letter-spacing: -1.3px;
        @include SP {
          line-height: 25px;
          letter-spacing: -1.7px;
        }
      }
    }
    &--parent {
      counter-reset: number;
      @include ipad {
        padding: 0 20px;
      }
      @include SP {
        padding: 0 15px;
      }
    } // -------------------------------------------------------
    &--item {
      max-width: 1000px;
      width: 100%;
      margin: 0 auto 85px;
      display: flex;
      position: relative;
      &:nth-of-type(2) {
        margin-bottom: 95px;
        @include SP {
          margin-bottom: 32px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      @include SP {
        flex-wrap: wrap;
        margin-bottom: 35px;
        overflow: hidden;
      }
      &::before {
        counter-increment: number;
        content: "0" counter(number);
        font-size: 91px;
        line-height: 20px;
        position: absolute;
        top: -5px;
        left: 53.2%;
        transform: translateX(-50%);
        font-weight: 500;
        font-family: "Roboto", sans-serif;
        letter-spacing: 3px;
        z-index: 3;
        @include ipad {
          left: 50%;
        }
        @include SP {
          display: none;
        }
      }
      .img {
        max-width: 529px;
        width: 100%;
        position: relative;
        &::before {
          counter-increment: number;
          content: "0" counter(number);
          line-height: 20px;
          position: absolute;
          transform: translateX(-50%);
          font-weight: 500;
          font-family: "Roboto", sans-serif;
          letter-spacing: 3px;
          display: none;
          font-size: 45.5px;
          bottom: 24px;
          left: auto;
          right: -34px;
          width: 24%;
          text-align: right;
          @include SP {
            display: block;
          }
        }
        @include ipad {
          max-width: 50%;
        }
        @include SP {
          // max-width: 245px;
          max-width: 100%;
          padding: 0 15.4% 0 0;
          img {
            width: 100%;
          }
        }
      }
      .content {
        max-width: calc(100% - 529px);
        width: 100%;
        padding: 0 0 0 71px;
        position: relative;
        top: -26px;
        @include ipad {
          max-width: 50%;
          padding-left: 50px;
        }
        @include SP {
          max-width: 100%;
          padding: 15px 0 0 0;
          top: auto;
        }
      }
    }
    &--slider {
      margin: 117px auto 0;
      @include SP {
        margin: 39px auto 0;
      }
      .slider {
        max-width: 855px;
        width: 100%;
        padding: 0 0 75px;
        margin: -51px auto 0;
        @include ipad {
          max-width: 650px;
        }
      }
      .item {
        display: flex !important;
        justify-content: space-between;
        align-items: flex-start;
        @include SP {
          display: block !important;
        }
      }
      .top__blockhd {
        background: #f1f1f1;
        padding: 53px 0 76px;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        @include SP {
          padding: 31px 0 43px;
          font-size: 24px;
          letter-spacing: -2px;
        }
      }
      .left {
        max-width: 352px;
        width: 100%;
        position: relative;
        margin: 5px 17px 0 0;
        border: 1px solid #000;
        .st-text,
        .nd-text {
          text-align: center;
          font-size: 16px;
          margin: 19px 0 17px;
          @include SP {
            margin: 8px 0 0;
          }
        }
        .st,
        .nd {
          @include PC {
          }
          @include SP {
            width: 50%;
          }
        }
        @include PC {
          padding: 23px 23px 8px;
        }
        @include ipad {
          max-width: 50%;
        }
        @include SP {
          max-width: 290px;
          margin: 28px auto 0;
          padding: 20px 20px 16px;
          display: flex;
        }
      }
      .after {
        @include ipad {
          max-width: 50%;
        }
      }
      .ttl {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        margin: 34px 6px 15px 6px;
        @include SP {
          margin: 18px 0 16px;
          letter-spacing: -1.9px;
        }
      }
      .text {
        text-align: justify;
        line-height: 24px;
        margin: 10px 22px 29px 6px;
        font-size: 14px;
        letter-spacing: -1.2px;
        @include SP {
          margin: 5px 0px 17px 0;
          font-size: 14px;
          letter-spacing: -0.7px;
          line-height: 23px;
        }
      }
      .prices {
        font-size: 20px;
        font-weight: bold;
        padding: 0 0 7px 0;
        position: relative;
        text-align: center;
        max-width: 318px;
        width: 100%;
        margin: 0 auto;
        line-height: 43px;
        @include SP {
          font-size: 16px;
          display: block;
          justify-content: center;
          line-height: 36px;
          align-items: center;
          padding-bottom: 8px;
          width: 88%;
          padding-left: 10px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #080404;
        }
        i {
          font-style: normal;
          @include PC {
            position: relative;
            top: -5px;
          }
        }
        span {
          font-size: 42px;
          font-weight: 500;
          font-family: "Roboto", sans-serif;
          padding: 0 6px 0 25px;
          line-height: 43px;
          letter-spacing: 4px;
          @include ipad {
            padding: 0 5px;
          }
          @include SP {
            font-size: 33px;
            letter-spacing: 3px;
            position: relative;
            top: 2px;
          }
        }
        em {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1px;
          @include SP {
            font-size: 13px;
            position: relative;
            right: 3px;
            top: 2px;
          }
        }
      }
      .content {
        max-width: 472px;
        width: 100%;
        padding: 5px 20px;
        @include ipad {
          max-width: 100%;
        }
        @include SP {
          margin-top: 12px;
          padding-left: 15px;
          padding-right: 15px;
          .img {
            img {
              // border: 1px solid #000;
              box-sizing: border-box;
              width: 216px;
              margin: 0 auto;
            }
          }
        }
      }
      // -----------------------------
      @include PC {
        .slick-prev:hover,
        .slick-prev:focus,
        .slick-next:hover,
        .slick-next:focus {
          outline: none;
          background-color: transparent;
          color: transparent;
        }
      }
      .slick-arrow {
        background: #ccc;
        z-index: 2;
        top: 41.8%;
        width: 48px;
        height: 48px;
        transition: all 0.2s ease-in-out;
        border: 1px solid #000;
        border-radius: 50%;
        @include PC {
          &:hover {
            opacity: 0.5;
          }
        }
        @include SP {
          top: 93px;
          width: 24px;
          height: 24px;
          opacity: 1 !important;
          display: block !important;
          border: 1px solid #000000a3;
        }
      }
      .slick-next {
        background: url("../img/lineup/ico_next.png") no-repeat;
        background-size: 9px 10px;
        right: -74px;
        background-position: center center;
        @include PC {
          &:focus,
          &:hover {
            background: url("../img/lineup/ico_next.png") no-repeat;
            background-size: 9px 10px;
            background-position: center center;
          }
        }
        @include ipad {
          right: -50px;
        }
        @include SP {
          right: 14px;
          background-size: 5px 6px;
          &:focus,
          &:hover {
            background: url("../img/lineup/ico_next.png") no-repeat;
            background-size: 5px 6px;
            background-position: center center;
          }
        }
        &::before {
          display: none;
        }
      }
      .slick-prev {
        background: none;
        background: url("../img/lineup/ico_prev.png") transparent no-repeat;
        background-size: 9px 10px;
        left: -73px;
        background-position: center center;
        @include PC {
          &:focus,
          &:hover {
            background: url("../img/lineup/ico_prev.png") transparent no-repeat;
            background-size: 9px 10px;
            background-position: center center;
          }
        }
        @include ipad {
          left: -50px;
        }
        @include SP {
          left: 16px;
          top: 97px;
          background-size: 5px 6px;
          &:focus,
          &:hover {
            background: url("../img/lineup/ico_prev.png") transparent no-repeat;
            background-size: 5px 6px;
            background-position: center center;
          }
        }
        &::before {
          display: none;
        }
      }
      .slick-dots {
        bottom: 0;
        @include SP {
          display: none !important;
        }
        li {
          &::before,
          button {
            display: none;
          }
          background: #f1f1f1;
          color: #f1f1f1;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          &.slick-active {
            opacity: 1;
            background: #080404;
            color: #080404;
          }
        }
      }
      .slick-slide {
        outline: none;
      }
    } // -------------------------------------------------------
    &--equipment {
      margin: 159px auto 0;
      @include SP {
        margin: 20px auto 0;
      }
      .top__blockhd {
        background: url(../img/lineup/ico_bags.png) no-repeat top center;
        background-size: 48px 35px;
        padding: 56px 0 14px 0;
        position: relative;
        top: -25px;
        @include SP {
          background-size: 30px 24px;
          padding: 43px 0 22px 0;
        }
      }
      .ttl {
        font-size: 16px;
        font-weight: bold;
        margin: 13px 0 14px;
        line-height: 22px;
        letter-spacing: 0.1px;
      }
      .txt {
        font-size: 14px;
        letter-spacing: -1px;
        @include SP {
          font-size: 14px;
          letter-spacing: -0.7px;
          margin: 10px 0 0;
          line-height: 21px;
        }
      }
    }
    &--wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 0 -20px;
    }
    &--details {
      // max-width: 306px;
      max-width: calc(100% / 3 - 40px);
      margin: 0 20px 57px;
      @include IE {
        max-width: calc(100% / 3 - 45px);
      }
      @include SP {
        margin: 0 16px 25px;
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
        .inner-sp {
          display: flex;
          .img-sp {
            max-width: 144px;
            width: 100%;
            margin: 4px 0 0;
          }
          .ttl {
            max-width: calc(100% - 144px);
            margin: 0;
            padding: 0 0 0 16px;
            font-size: 14px;
          }
        }
      }
    }
    &--filled {
      max-width: 800px;
      width: 100%;
      margin: 41px auto;
      border: 1px solid #080404;
      padding: 24px 21px 34px 34px;
      @include ipad {
        max-width: 730px;
      }
      @include SP {
        max-width: 295px;
        margin: 45px auto;
        padding: 24px 15px 27px 18px;
      }
      .subtit {
        font-size: 16px;
        font-weight: bold;
        margin: 10px auto 27px;
        text-align: center;
        position: relative;
        max-width: 145px;
        width: 100%;
        padding: 0 0 10px;
        position: relative;
        right: 5px;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background: #080404;
        }
        @include SP {
          font-size: 14px;
          margin: 0 auto 19px;
          max-width: 124px;
          right: 0;
        }
      }
      .text {
        font-size: 14px;
        letter-spacing: 0.6px;
        font-feature-settings: "palt";
        @include SP {
          font-size: 14px;
          letter-spacing: 1px;
          font-feature-settings: "palt";
          line-height: 21px;
        }
      }
    } // -------------------------------------------------------
    &--work {
      margin: 162px auto 0;
      overflow: hidden;
      &.display {
        display: block;
      }
      &.hiden {
        display: none;
      }
      @include SP {
        margin: 79px auto 0;
      }
      .item {
        width: 25%;
        display: inline-block;
        vertical-align: top;
        padding: 0 3px;
        font-size: 14px;
        text-align: left;
        transition: all 0.3s;
        img {
          width: 100%;
        }
        @include PC {
          &:hover {
            opacity: 0.5;
            background: #e9e9e9;
          }
        }
        @include SP {
          width: 50%;
          padding: 0 2px;
          margin: 0 0 40px;
        }
      }
      .top__cmbtn {
        max-width: 260px;
        width: 100%;
        margin: 0 auto;
        display: block;
        @include SP {
          max-width: 235px;
        }
      }
      .top__blockhd {
        background-image: url(../img/top/ico_works.svg);
        background-size: 42px 38px;
        padding-top: 56px;
        letter-spacing: 3.5px;
        font-size: 30px;
        @include SP {
          background-size: 28px 26px;
          padding-top: 35px;
          font-size: 21px;
          letter-spacing: 2px;
        }
      }
      .item__content {
        padding: 15px 18px;
        @include SP {
          padding: 3px 11px;
        }
      }
      .item__cat {
        p {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
          border: 1px solid #080404;
          padding: 2px 7px 4px;
          margin: 9px 9px 9px 0;
          letter-spacing: 0.5px;
          @include SP {
            margin-bottom: 0;
          }
        }
      }
      .item__title {
        margin: 0 0 7px;
        @include SP {
          margin: 0;
        }
      }
    }
    &--area {
      font-size: 0;
      margin: 48px -3px 69px;
      display: flex;
      justify-content: center;
      @include SP {
        margin: 33px -3px 1px;
        flex-wrap: wrap;
        // img {
        //   height: 206px;
        // }
      }
      .item__title {
        font-size: 18px;
        font-weight: bold;
        @include SP {
          font-size: 15px;
          letter-spacing: 1px;
          text-decoration: underline;
        }
      }
      .item__text {
        font-size: 14px;
        @include SP {
          font-size: 12px;
          letter-spacing: 0.5px;
        }
      }
    } // -------------------------------------------------------
    &--event {
      margin: 96px auto 0;
      padding: 0 0 78px;
      @include PC {
        // background: #f1f1f1;
        background: rgba(241, 241, 241, 0.5);
      }
      @include SP {
        margin: 116px auto 0;
        padding-bottom: 68px;
        background: rgba(241, 241, 241, 0.35);
      }
      .top__blockhd {
        background-image: url(../img/lineup/ico_flags.png);
        background-size: 34px 42px;
        padding-top: 77px;
        font-size: 30px;
        font-feature-settings: "palt";
        letter-spacing: 4px;
        position: relative;
        top: -11px;
        @include SP {
          font-size: 22px;
          background-size: 22px 27px;
          padding-top: 45px;
          line-height: 28px;
          letter-spacing: 2px;
        }
      }
      .top__cmbtn {
        max-width: 260px;
        width: 100%;
        margin: 0 auto;
        display: block;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        right: 7px;
        @include SP {
          max-width: 232px;
        }
      }
      .top__event--inner {
        margin-top: 36px;
        margin-bottom: 24px;
        @include PC {
          .top__event--content {
            width: calc(100% / 3);
          }
        }
        @include SP {
          margin-bottom: 17px;
          margin-top: 11px;
        }
      }
      @include SP {
        margin: 54px auto 0;
        .top__event--content {
          margin-top: 13px;
          margin-bottom: 27px;
          .title {
            margin: 14px 0 0;
          }
          .txt {
            .cat {
              text-align: center;
            }
            .date {
              margin: 13px 0 0 3px;
              span {
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
