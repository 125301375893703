@charset 'UTF-8';

/*-----------------------------------------------------------
Footer
------------------------------------------------------------*/
.contactBox {
	background: #f1f1f1;
	text-align: center;
	padding: 69px 0 60px;

	&__hd {
		font-size: 32px;
		letter-spacing: 1.2px;
		margin-bottom: -1px;
		display: block;
	}
	&__2cols {
		@include clearfix();
		margin-top: 60px;
	}
	&__left,
	&__right {
		background: #fff;
		padding-left: 10px;
		padding-right: 10px;

		strong,
		small {
			display: block;
		}
		strong {
			font-size: 20px;
			letter-spacing: 1.5px;
		}
	}
	&__left {
		width: 39.2%;
		float: left;
		padding-top: 34px;
		padding-bottom: 42px;

		span {
			display: inline-block;
			font-size: 36px;
			font-weight: 900;
			padding-left: 24px;
			background: url(../img/common/icon/ico_tel_big.png) no-repeat center left;
			background-size: 17px 22px;
			line-height: 1;
			margin: 12px 0 7px;
			letter-spacing: 1.5px;
		}
		small {
			font-size: 14px;
			letter-spacing: 2px;
		}
	}
	&__right {
		width: 60%;
		float: right;
		padding-top: 35px;
		a {
		}
	}
	&__btn {
		@include clearfix();
		max-width: 497px;
		margin: 13px auto 0;

		a {
			background-image: url(../img/common/icon/ico_ar_right_round_pc.png);
			background-repeat: no-repeat;
			background-position: center right 13px;
			max-width: 242px;
			width: 50%;
			float: left;
			height: 64px;
			display: block;
			text-align: center;
			padding-top: 20px;

			&:first-child {
				background-color: #d53230;
				span {
					left: -11px;
				}
			}
			&:last-child {
				background-color: #080404;
				float: right;
			}
			span {
				color: #fff;
				font-weight: bold;
				font-size: 16px;
				display: block;
				position: relative;
			}
		}
	}
	@media (min-width: 768px) and (max-width: 999px) {
		&__left {
			span {
				font-size: 32px;
			}
		}
		&__btn {
			a {
				width: 49%;

				span {
					font-size: 14px;
				}
				&:last-child {
					span {
						left: -5px;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		padding: 38px 0 46px;

		&__inner {
			padding-left: 0;
			padding-right: 0;
		}
		&__hd {
			font-size: 19px;
			letter-spacing: 0.8px;
			margin-bottom: 13px;
		}
		p {
			font-size: 13px;
		}
		&__left,
		&__right {
			width: 100%;
			float: none;
			padding-left: 15px;
			padding-right: 15px;

			strong {
				font-size: 16px;
				letter-spacing: 1.2px;
			}
		}
		&__left {
			margin-bottom: 11px;
			padding-top: 23px;
			padding-bottom: 23px;

			span {
				font-size: 26px;
				background-size: 12px 16px;
				padding-left: 17px;
				letter-spacing: 0.8px;
				margin: 0 0 0 0;
			}
			small {
				font-size: 12px;
				color: #333;
				text-align: left;
				letter-spacing: 0.9px;
				margin: -2px 0 11px;
				padding-left: 5px;
			}
			p {
				font-size: 12px;
				margin-left: -22px;
				margin-right: -32px;
				background: #080404;
				color: #fff;
				text-align: center;
				padding: 5px 0;
				letter-spacing: 0.5px;
			}
		}
		&__right {
			padding-top: 26px;
			padding-bottom: 17px;

			strong {
				letter-spacing: 0.3px;
			}
			a {
				width: 100%;
				float: none;
			}
		}
		&__telbox {
			margin: 6px auto 0;
			background: url(../img/common/icon/ico_ar_right_round_black.png) no-repeat top 28px right 9px;
			background-size: 22px 22px;
			border: 1px solid #080404;
			text-align: left;
			padding: 16px 32px 0 22px;
			position: relative;

			&--num {
				display: block;
				max-width: 204px;
				margin: auto;
			}
			a {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
		&__2cols {
			margin-top: 30px;
			padding: 0 15px;
		}
		&__btn {
			margin-top: 9px;
			max-width: 100%;

			a {
				max-width: 100%;
				background: url(../img/common/icon/ico_ar_right_round.png) no-repeat center right 7px;
				background-size: 22px 22px;
				height: 60px;
				padding-top: 18px;

				span {
					font-size: 15px;
				}
				&:first-child {
					margin-bottom: 16px;

					span {
						left: 0;
						letter-spacing: 1px;
					}
				}
			}
		}
	}
}
.footer {
	padding-top: 65px;
	padding-bottom: 110px;

	&__gotop {
		position: fixed;
		right: -38px;
		bottom: 27px;
		font-size: 12px;
		text-align: center;
		width: 38px;
		z-index: 9;

		img {
			display: block;
			margin-bottom: 5px;
		}
		&.show {
			right: 20px;
		}
	}
	&__inner {
		@include clearfix();
		margin-bottom: 13px;
	}
	&__left,
	&__right {
		float: left;
	}
	&__left {
		width: 34.9%;

		.logo {
			max-width: 213px;
			display: block;
		}
		strong {
			display: block;
			font-size: 18px;
			margin-top: 10px;
			letter-spacing: 1px;
		}
		p {
			margin-top: 13px;
			line-height: 1.9;
			letter-spacing: 0.5px;
		}
	}
	&__right {
		width: 65.1%;
		padding-top: 10px;

		ul {
			width: 45.3%;
			float: left;

			li {
				margin-bottom: 22px;

				&:last-child {
					margin-bottom: 0;
					width: 100% !important;
				}
				a {
					background: url(../img/common/icon/ico_ar_right_gray.png) no-repeat center left;
					background-size: 12px 6px;
					padding-left: 23px;
				}
			}
			&:last-child {
				float: right;
			}
		}
	}
	&__copyright {
		font-size: 12px;
		letter-spacing: 0.6px;
	}
	@media (min-width: 768px) and (max-width: 999px) {
		&__right {
			ul {
				li {
					a {
						font-size: 12px;
					}
				}
				&:first-child {
					width: 51%;
				}
			}
		}
	}
	@media (max-width: 767px) {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 28px;
		padding-bottom: 50px;

		&__inner {
			margin-bottom: 40px;
		}
		&__gotop {
			width: 36px;
			bottom: 58px;

			img {
				margin-bottom: 3px;
			}
			&.show {
				right: 18px;
			}
		}
		&__right {
			width: 100%;
			float: none;

			ul {
				font-size: 0;
				width: 100%;

				li {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					margin-bottom: 15px;

					a {
						display: block;
						background-size: 10px 6px;
						background-position: top 7px left 2px;
						font-size: 13px;
						border-bottom: 1px dashed #080404;
						padding: 0 0 11px 20px;
					}
				}
			}
			&--2cols {
				margin: 0 -10px -4px;
				width: auto !important;

				li {
					width: 50% !important;
					padding: 0 10px;

					a {
						letter-spacing: -1.5px;
					}
				}
			}
		}
		&__copyright {
			font-family: "Roboto", sans-serif;
			font-size: 12px;
			text-align: center;
			font-weight: 500;
		}
	}
}
