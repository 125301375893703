@charset 'UTF-8';

.sitemap {

  &__content {
    @include clearfix();
    max-width: 840px;
    padding: 0 20px;
    margin: 98px auto 173px;

    &--col {
      width: 47%;
      max-width: 350px;

      ul {

        li {
          padding-bottom: 18px;
          border-bottom: 1px dashed #080404;

          a {
            font-weight: bold;
            display: block;
            font-size: 16px;
            padding-left: 29px;
            background: url(../img/sitemap/ico_ar_right.png) no-repeat top 9px left;
          }
          span {
            display: block;
            color: #808080;
            margin: 9px 0 7px 0;
            position: relative;
            left: -2px;
          }
        }
      }
      .sublink {
        background: url(../img/sitemap/ico_ar_right.png) no-repeat top 6px left;
        display: block;
        padding-left: 32px;
        margin-bottom: 16px;
      }
    }
    &--left {
      float: left;

      ul {
        li {
          margin-bottom: 25px;
        }
      }
    }
    &--right {
      float: right;

      ul {
        margin-bottom: 39px;

        li {
          margin-bottom: 21px;

          a {
            padding-left: 33px;
          }
        }
      }
    }
  }
  @media (max-width:767px) {

    &__content {
      padding: 0 15px;
      margin: 15px auto 86px;

      &--col {
        float: none;
        width: 100%;
        max-width: 100%;

        ul {

          li {
            padding-bottom: 20px;

            a {
              background: url(../img/sitemap/ico_ar_right_sp.png) no-repeat top 7px left;
              background-size: 10px 5px;
              font-size: 13px;
              text-decoration: underline;
              display: inline-block;
              padding-left: 19px;
            }
            span {
              font-size: 13px;
              position: static;
              margin: 7px 0 1px;
              padding-left: 2px;
            }
          }
        }
        .sublink {
          font-size: 12px;
          background: url(../img/sitemap/ico_ar_right_sp.png) no-repeat top 9px left;
          background-size: 10px 5px;
          text-decoration: underline;
          padding-left: 18px;
          margin-bottom: 17px;
        }
      }
      &--left {

        ul {

          li {
            margin-bottom: 21px;
          }
        }
      }
      &--right {

        ul {
          margin-bottom: 28px;

          li {
            margin-bottom: 18px;
          }
        }
      }
    }
  }
}
