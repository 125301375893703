@charset "utf-8";

.recruitpage {

	#wrap {
		overflow: hidden;
	}
	&__logo {
		display: block;
		margin: 43px auto 26px;
		width: 223px;
		font-size: 0;
	}
	&__inner {
		max-width: 840px;
		padding-left: 20px;
		padding-right: 20px;
		margin: auto;
	}
	&__hd {
		text-align: center;

		strong {
			display: inline-block;
			font-size: 22px;
			border-bottom: 2px solid #080404;
			padding-bottom: 8px;
			letter-spacing: 1px;
		}
	}
	&__tel {
		text-align: center;
		margin: 24px auto 91px;

		&--main {
			position: relative;
		}
		&--num {
			font-weight: 900;
			background: url(../img/common/icon/ico_tel_big.png) no-repeat center left;
			background-size: 21px 27px;
			padding-left: 30px;
			font-size: 44px;
			display: inline-block;
			line-height: 1;
			letter-spacing: 2px;
			margin-bottom: 10px;
		}
		small {
			display: block;
			letter-spacing: 1.7px;
		}
	}
	&__block01 {
		padding: 78px 0 0;
		margin-bottom: 75px;

		&--txt {
			text-align: center;

			strong {
				display: block;
		    font-size: 26px;
		    letter-spacing: -0.5px;
				margin-bottom: 28px;
			}
			p {
				font-size: 16px;
				line-height: 2.2;
				margin-bottom: 74px;
			}
		}
		&--img {

			img {
				display: block;
				width: 100%;
			}
		}
	}
	&__block02 {
		max-width: 990px;
		padding: 0 20px;
		margin: 0 auto 226px;

		ul {
			margin-left: -12px;
			margin-right: -12px;
			text-align: center;
			font-size: 0;

			li {
				display: inline-block;
				vertical-align: top;
				width: 25%;
				padding: 0 12px;

				a {
					display: block;
					font-size: 16px;
					border: 1px solid #080404;
					position: relative;
					padding: 8px 5px;
					letter-spacing: 0.5px;

					&::before {
						content: "";
						background: url(../img/recruit/ico_ar_down.svg) no-repeat center center;
						background-size: 100% 100%;
						position: absolute;
						left: 0;
						right: 0;
						margin: auto;
						bottom: -13px;
						width: 10px;
						height: 18px;
					}
				}
			}
		}
	}
	&__block03 {
		margin-bottom: 128px;

		ul {

			li {
				position: relative;
				margin-bottom: 82px;

				> img {
					position: absolute;
					top: -39px;
					right: 0;
					width: 490px;
				}
				.txt {
					@include clearfix();

					&__content {
						position: relative;
						float: left;
						background: #f1f1f1;
						font-size: 16px;
						padding: 66px 67px 69px;

						&::before {
							content: "";
							background: #f1f1f1;
							width: 73px;
							height: 100%;
							position: absolute;
							top: 0;
							right: -73px;
						}
						strong {
							display: block;
							font-size: 20px;
							margin-bottom: 20px;
						}
						p {
							line-height: 1.65;
							letter-spacing: -0.6px;
						}
					}
				}
				&:nth-child(2n+2) {

					> img {
						left: 0;
						right: auto;
					}
					.txt {

						&__content {
							float: right;
							padding-left: 55px;
    					padding-right: 70px;

							&::before {
								right: auto;
								left: -73px;
							}
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&__block04 {
		max-width: 842px;
		margin: 0 auto 204px;
		padding: 0 20px;

		&--inner {
			border: 2px solid #f1f1f1;
			padding: 35px 39px 37px;
		}
		&--author {
			@include clearfix();
			margin-bottom: 36px;

			img {
				float: left;
				width: 124px;
			}
			.txt {
				padding: 8px 0 0 42px;
				overflow: hidden;

				strong, small, span {
					display: block;
				}
				strong {
					font-size: 26px;
					margin-bottom: 16px;
				}
				small {
					letter-spacing: 1px;
				}
				span {
					font-size: 20px;
					letter-spacing: 2px;
				}
			}
		}
		&--desc {
			font-size: 16px;
			line-height: 2.15;
			padding: 0 4px;
			margin-bottom: 36px;
		}
		a {
			display: inline-block;
			text-decoration: underline;
			position: relative;
			margin-left: 3px;
			letter-spacing: 1px;

			&::before {
				content: "";
				background-image: url(../img/recruit/ico_ar_right.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				background-position: center center;
				position: absolute;
				right: -14px;
				top: 50%;
				margin-top: -3px;
				width: 12px;
				height: 6px;
			}
		}
	}
	&__block05 {
		max-width: 840px;
		padding: 0 20px;
		margin: 0 auto 244px;

		&--hd {
			margin-bottom: 62px;
			display: block;
			text-align: center;
			font-weight: bold;
			font-size: 34px;
			letter-spacing: 2px;

			small {
				display: block;
				font-weight: normal;
				font-size: 16px;
				margin-top: 4px;
				letter-spacing: 1px;
			}
		}
		&--desc {
			font-size: 16px;
			line-height: 2.15;
			text-align: justify;
			margin-bottom: 59px;
		}
		&--table {
			border: 1px solid #080404;

			table {

				tr:last-child {
					th,td {
						border-bottom: 0;
					}
				}
				th,td {
					font-weight: normal;
					text-align: left;
					font-size: 16px;
					border-bottom: 1px solid #080404;
					padding: 24px 24px 22px;
					letter-spacing: 0.5px;
				}
				th {
					width: 245px;
					background: #f1f1f1;
				}
				td {

					span {
						display: block;
						position: relative;
						padding-left: 26px;
						margin-bottom: 7px;

						&::before {
							content: "";
							width: 7px;
							height: 7px;
							@include border-radius(50%);
							background: #080404;
							position: absolute;
							top: 50%;
							margin-top: -4px;
							left: 9px;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
					p {
						margin-bottom: 33px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	&__mainhd {
		background: #f1f1f1;
		text-align: center;
		padding: 60px 15px 65px;
		margin-bottom: 100px;

		strong, span {
			display: block;
		}
		strong {
			font-size: 34px;
			margin-bottom: 10px;
		}
		span {
			font-size: 16px;
			letter-spacing: 1px;
		}
	}
	.step {
		img {
			display: block;
			margin: auto;
		}
	}
	.step-1 {
		margin: 49px 0 46px;
	}
	.step-2 {
		margin: 85px auto 46px;
	}
	.step-3 {
		margin: 145px auto 103px;
	}
	.tableContact {
		max-width: 780px;
		margin: auto;
		border: 1px solid #080404;

		th,td {
			text-align: left;
			border-bottom: 1px solid #b2b2b2;
			font-size: 16px;
			padding-top: 24px;
			padding-bottom: 21px;
			padding-right: 26px;
			letter-spacing: 0.5px;
			vertical-align: middle;
		}
		tr:last-child {
			th,td {
				border: 0;
			}
			@media (min-width:768px) and (max-width:799px) {
				th {
					br:last-child {
						display: none;
					}
				}
			}
		}
		th {
			font-weight: bold;
			width: 244px;
			background: #f1f1f1;
			padding-left: 83px;
			padding-right: 5px;
			position: relative;
			background-clip: padding-box;

			&.vtop {
				vertical-align: top;

				em {
					top: 27px;
				}
			}
			em {
				position: absolute;
				top: 32px;
				left: 22px;
				color: #fff;
				background: #d53230;
				font-size: 12px;
				font-weight: normal;
				padding: 1px 12px;
				margin-right: 12px;

				&.unrq {
					background: #808080;
				}
			}
		}
		td {
			padding-left: 26px;

			input, textarea {
				outline: none;
			}
			input[type="text"], input[type="tel"], input[type="email"], input[type="number"] {
				@include border-radius(5px);
				border: 1px solid #a8a8a8;
				background: #f7d6d6;
				width: 100%;
				padding: 8px 15px;
				letter-spacing: -0.5px;
				width: 270px;

				&.unrq, &.pass {
					background: #fff;
				}
			}
			textarea {
				display: block;
				width: 100%;
				@include border-radius(5px);
				border: 1px solid #808080;
				padding: 5px 10px;
    		height: 179px;
				line-height: 1.5;
			}
			&.addblock {

				span {
					margin-right: 12px;
				}
			}
			#zipcode {
				width: 169px;
			}
			#address {
				display: block;
				margin-top: 15px;
				width: 457px;
			}
			#email {
				width: 362px;
			}
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #949494;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #949494;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #949494;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #949494;
		}
		.bookdate {
			font-size: 0;

			&__row:first-child {
				margin-bottom: 25px;
			}
			span,input {
				font-size: 16px;
				display: inline-block;
				vertical-align: middle;
			}
			.ipyear {
				width: 169px;
				margin: 0 6px 0 18px;
			}
		}
	}
	.formBlock {

		.notetxt {
			max-width: 780px;
			margin: 32px auto 15px;

			small, strong {
				display: block;
			}
			small {
				color: #d53230;
				letter-spacing: -0.4px;
				margin-bottom: 28px;
			}
			strong {
				margin-bottom: 9px;
			}
			p {
				line-height: 1.9;

				a {
					color: #d53230;
					text-decoration: underline;
				}
			}
		}
		.chkform {
			max-width: 780px;
			margin: 0 auto 96px;
			text-align: center;
			font-size: 0;
			background: #f9e0e0;

			&.active {
				background: #e5e5e5;
			}
			label {
				display: block;
				font-size: 16px;
				padding: 24px 0;
				cursor: pointer;
			}
			input, span {
				display: inline-block;
				vertical-align: middle;
			}
			input {
				outline: none;
				width: 22px;
				height: 22px;
				margin-right: 20px;
			}
			&.pass {
				background: #e5e5e5;
			}
		}
		.formbtn {
			outline: none;
			@include border-radius(0);
			border: 0;
			background: #d53230 url(../img/common/icon/ico_ar_right_round_pc.png) no-repeat center right 16px;
			color: #fff;
			width: 323px;
			height: 70px;
			font-weight: bold;
			font-size: 18px;
			display: block;
			margin: 0 auto;
			cursor: pointer;
			@include transition(all .3s);
			line-height: 74px;
			letter-spacing: 1.5px;

			&:hover, &.disabled {
				opacity: 0.5;
			}
			&.disabled:hover {
				cursor: default;
			}
		}
		.mailtxt {
			max-width: 780px;
			margin: 0 auto;
			text-align: center;
			margin: 59px auto 116px;

			a {
				text-decoration: underline;
				color: #d53230;
			}
		}
		.gobacktxt {
			text-align: right;
			max-width: 780px;
			margin: 27px auto 108px;

			a {
				display: inline-block;
				text-decoration: underline;
			}
		}
	}
	.formConfirm {

		.tableContact {

			th, td {
				padding-top: 21px;
    		padding-bottom: 17px;
			}
			th {
				font-weight: bold;
				padding-left: 22px;
				position: static;
			}
			td {

				.confirm_date {
					letter-spacing: 1px;

					li {

						&:first-child {
							margin-bottom: 12px;
						}
						span {
							margin-left: 17px;

							&:first-of-type {
								margin-left: 25px;
							}
						}
					}
				}
			}
		}
	}
	&__copyright {
		text-align: center;
		font-size: 12px;
		letter-spacing: 0.6px;
		margin-bottom: 60px;
	}
	@media (min-width:768px) and (max-width:899px) {
		&__block02 {
			ul {
				li {
					a {
						font-size: 13px;
					}
				}
			}
		}
	}
	@media (min-width:768px) and (max-width:1023px) {
		&__block03 {
			ul {
				li {
					>img {
						width: 350px;
					}
					.txt {
						&__content {
							padding-left: 25px;
							padding-right: 25px;
						}
					}
					&:nth-child(2n+2) {
						.txt {
							&__content {
								padding-left: 25px;
								padding-right: 25px;
							}
						}
					}
				}
			}
		}
	}
	@media (max-width:767px) {
		padding: 0;

		&__logo {
			width: 129px;
			margin: 4px auto 3px;
		}
		&__inner {
			padding-left: 15px;
			padding-right: 15px;
		}
		&__hd {

			strong {
				font-size: 16px;
				border: 0;
				padding: 0;
			}
		}
		&__block01 {
			padding: 41px 0 0;
			margin-bottom: 40px;

			&--txt {
				padding-left: 15px;
				padding-right: 15px;

				strong {
					font-size: 17px;
					line-height: 1.55;
					margin-bottom: 14px;
				}
				p {
					font-size: 13px;
					text-align: justify;
					line-height: 1.65;
					margin-bottom: 25px;
				}
			}
			&--img {
				padding: 0 15px;
			}
		}
		&__block02 {
			padding: 0 15px;
			margin-bottom: 93px;

			ul {
				margin-left: -5px;
				margin-right: -5px;

				li {
					width: 50%;
					padding: 0 5px;
					margin-bottom: 29px;

					a {
						font-size: 14px;
						padding: 0;
						line-height: 1.2;
						display: table;
						width: 100%;
						height: 51px;

						&::before {
							width: 12px;
							height: 23px;
							bottom: -14px;
						}
						span {
							display: table-cell;
							width: 100%;
							vertical-align: middle;
						}
					}
				}
			}
		}
		&__block03 {
			margin-bottom: 75px;

			ul {

				li {
					padding: 0 15px;
					margin-bottom: 38px;

					.txt {
						padding: 0;

						&__content {
							width: 100%;
							float: none;
							font-size: 13px;
							padding: 44px 20px 26px !important;

							&::before {
								display: none;
							}
							strong {
								font-size: 14px;
								text-align: center;
								letter-spacing: 0.5px;
								margin-bottom: 8px;
							}
							p {
								text-align: justify;
							}
						}
					}
					> img {
						position: static;
						display: block;
						width: 100%;
					}
				}
			}
		}
		&__block04 {
			padding: 0 0 78px;
			border-bottom: 1px solid #808080;
			margin-bottom: 65px;

			&--inner {
				border: 0;
				padding: 0;
			}
			&--author {
				padding: 21px 15px 14px;
				background: #f1f1f1;
				margin-bottom: 27px;

				img {
					width: 80px;
				}
				.txt {
					padding: 0 0 0 20px;

					strong {
						font-size: 16px;
						line-height: 1.4;
						letter-spacing: 0.5px;
						margin-bottom: 8px;
						text-align: justify;
					}
					small {
						font-size: 11px;
						margin-bottom: 2px;
					}
					span {
						font-size: 13px;
						letter-spacing: 1.5px;
					}
				}
			}
			&--desc {
				font-size: 13px;
				padding: 0 16px;
				line-height: 1.65;
				text-align: justify;
				margin-bottom: 13px;
			}
			a {
				margin-left: 15px;

				&::before {
					width: 11px;
					right: -17px;
				}
			}
		}
		&__block05 {
			padding: 0 15px;
			margin-bottom: 105px;

			&--hd {
				font-size: 15px;
				letter-spacing: 1.5px;
				margin-bottom: 24px;

				small {
					font-size: 13px;
					margin-top: 2px;
				}
			}
			&--desc {
				font-size: 13px;
				line-height: 1.65;
				letter-spacing: 0.2px;
				margin-bottom: 42px;
			}
			&--table {
				border-color: #989898;

				table {

					tr:first-child th {
						border: 0;
					}
					th, td {
						width: 100%;
						display: block;
						font-size: 13px;
						border-bottom: 0;
					}
					th {
						border-top: 1px solid #989898;
						font-weight: bold;
						padding: 9px 10px 8px 16px;
					}
					td {
						padding: 12px 10px 11px 16px;

						span {
							padding-left: 12px;
							margin-bottom: -1px;

							&::before {
								width: 6px;
								height: 6px;
								left: 0;
							}
						}
						p {
							line-height: 1.35;
							margin-bottom: 22px;
						}
					}
				}
			}
		}
		&__mainhd {
			padding: 26px 15px 27px;
			margin-bottom: 31px;

			strong {
				font-size: 17px;
				margin-bottom: 6px;
			}
			span {
				font-size: 13px;
				line-height: 1.45;
				letter-spacing: 0;
			}
		}
		&__tel {
			border: 1px solid #080404;
			margin: 10px auto 49px;
			width: 260px;
			position: relative;

			&--main {
				max-width: 288px;
				margin: auto;
				text-align: left;
				padding: 16px 10px 10px 22px;
				background: url(../img/common/icon/ico_ar_right_round_black.png) no-repeat top 28px right 10px;
				background-size: 22px 22px;
			}
			&--num {
				background-size: 13px 17px;
				font-size: 26px;
				letter-spacing: 0.3px;
				margin-bottom: 0;
				padding-left: 17px;
				display: block;
			}
			small {
				font-size: 12px;
				letter-spacing: 1px;
				padding-left: 4px;
			}
			p {
				color: #fff;
		    font-size: 12px;
		    background: #080404;
		    text-align: center;
		    padding: 5px;
		    letter-spacing: 0.5px;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				width: 100%;
				height: 100%;
			}
		}
		.step-1 {
			margin: 14px 0 29px;
		}
		.step-2 {
			margin: 32px auto 35px;
		}
		.step-3 {
			margin: 33px auto 22px;
		}
		.tableContact-wrapper {
			margin-left: -15px;
			margin-right: -15px;
		}
		.tableContact {
			border: 0;
			border-top: 1px solid #989898;

			th, td {
				background: none;
				display: block;
				width: 100%;
				padding-left: 16px;
				padding-right: 16px;
			}
			th {
				border: 0;
				font-size: 14px;
				padding-top: 17px;
    		padding-bottom: 12px;
				padding-left: 78px;

				em {
					top: 17px !important;
					left: 17px;
					padding: 1px 12px 2px;
				}
			}
			td {
				padding-top: 0;
				font-size: 13px;
				padding-bottom: 22px;

				input[type="text"], input[type="tel"], input[type="email"], input[type="number"], textarea {
					-webkit-appearance: none;
			    -moz-appearance: none;
			    appearance: none;
					box-shadow: none;
				}
				input[type="text"], input[type="tel"], input[type="email"], input[type="number"] {
					width: 100%;
					padding: 0 10px;
					height: 47px;
					letter-spacing: 0;
				}
				textarea {
					height: 132px;
				}
				#address, #email, #tel {
					width: 100%;
				}
				#zipcode {
					width: 214px;
				}
				#address {
					margin-top: 14px;
				}
				&.addblock {

					span {
						font-size: 14px;
						margin: 0 9px 0 8px;
						position: relative;
						top: 2px;
					}
				}
			}
			.bookdate {

				&__row {
					margin: 0 -5px 0 0;

					&:first-child {
						margin-bottom: 15px;
					}
				}
				input {
					margin: 0 5px 0 11px;
				}
				span {
					font-size: 14px;

					&:first-child {
						display: block;
						margin-bottom: 4px;
						padding-left: 9px;
					}
				}
				.ipyear {
					font-size: 13px;
					width: 214px;
					margin: 0 5px 0 0;
				}
			}
		}
		.formBlock {

			.notetxt {
				margin: -4px auto 19px;

				small {
					font-size: 12px;
					letter-spacing: 0px;
					line-height: 1.5;
					margin-bottom: 14px;
				}
				strong {
					margin-bottom: 4px;
				}
				p {
					line-height: 1.5;
					font-size: 12px;
				}
			}
			.chkform {
				margin: 0 10px 38px;

				input {
					width: 17px;
					height: 17px;
					margin-right: 13px;
				}
				label {
					padding: 12px 0 10px;
					font-size: 14px;

					span {
						letter-spacing: 0.7px;
					}
				}
			}
			.formbtn {
				width: 260px;
				font-size: 15px;
				background-image: url(../img/common/icon/ico_ar_right_round.png);
				background-repeat: no-repeat;
				background-position: center right 7px;
				background-size: 22px 22px;
				height: 60px;
				line-height: 56px;

				span {

				}
			}
			.mailtxt {
				font-size: 12px;
				text-align: left;
				line-height: 1.5;
				margin: 33px -5px 42px;
			}
		}
		.formConfirm {

			.tableContact {
				border-top: 0;

				tr {
					&:first-child {
						th {
							padding-top: 4px;
						}
					}
					&:last-child {
						td {
							border-bottom: 1px solid #b2b2b2;
						}
					}
				}
				th {
					padding-top: 22px;
					padding-bottom: 2px;
					padding-left: 17px;
				}
				td {
					padding-top: 4px;
					padding-bottom: 21px;

					.confirm_date {
						padding: 12px 0 0 9px;

						li {

							&:first-child {
								margin-bottom: 8px;
							}
							span {
								margin-left: 9px;

								&:first-of-type {
									margin-left: 19px;
								}
							}
						}
					}
				}
			}
			.gobacktxt {
				margin: 17px auto 35px;

				a {
					font-size: 13px;
					margin-right: 2px;
				}
			}
		}
		&__copyright {
			font-family: "Roboto", sans-serif;
			margin-bottom: 25px;
		}
		.footer__gotop {
			bottom: 26px;

			&.show {
				right: 20px;
			}
		}
	}
}
.thankspage {

	.recruitpage__inner {
		max-width: 865px;
		margin-bottom: 131px;
	}
	&__content {
		font-size: 16px;
		margin-bottom: 119px;
	}
	&__btn {
		text-align: center;
	}
	.top__cmbtn {
		margin: auto;
	}
	@media (max-width: 767px) {

		.recruitpage__inner {
			margin-bottom: 100px;
		}
		&__content {
			font-size: 13px;
			margin-bottom: 48px;
			letter-spacing: 0.6px;
		}
	}
}
