@charset 'UTF-8';

.aboutus {
  &__hd {
    font-weight: bold;
    display: block;
    text-align: center;
    font-size: 34px;
    line-height: 1.4;
    letter-spacing: 1.5px;
    background-repeat: no-repeat;
    background-position: top center;

    small {
      display: block;
      font-size: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 4px;
    }
  }
  &__mainimg {
    background: url(../img/aboutus/img_about01.jpg) no-repeat center center;
    background-size: cover;
    margin: -53px 0 79px;
    height: 780px;

    &--txt {
      padding: 0 15px;
      font-size: 16px;
      text-align: center;
      padding-top: 99px;

      strong {
        display: block;
        font-size: 34px;
        margin-bottom: 20px;
      }
      p {
        line-height: 1.9;
      }
    }
  }
  &__block01 {
    .aboutus__hd {
      background-image: url(../img/aboutus/ico_hand.svg);
      background-size: 38px 56px;
      padding-top: 79px;
      margin-bottom: 60px;
    }
    ul {
      border-top: 1px dashed #080404;

      li {
        padding: 63px 0 34px;
        border-bottom: 1px dashed #080404;
        width: 100%;
        display: inline-block;
        vertical-align: top;

        .txt,
        .img {
          float: left;
        }
        .txt {
          width: 53%;
          text-align: center;
          padding-right: 7%;

          span {
            display: inline-block;
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 2px;
            border-bottom: 2px solid #080404;
            position: relative;
            top: -1px;
            margin-bottom: 24px;
          }
          strong {
            display: block;
            font-size: 26px;
            line-height: 1.3;
            letter-spacing: 0.5px;
            margin-bottom: 24px;
          }
          p {
            font-size: 16px;
            text-align: left;
          }
        }
        .img {
          width: 47%;

          img {
            display: block;
            width: 100%;
            margin-bottom: 11px;
          }
          p {
            font-size: 12px;
            text-align: right;
          }
        }
        &:nth-child(2) {
          padding-top: 55px;
          padding-bottom: 50px;
        }
        &:last-child {
          padding-top: 39px;
          padding-bottom: 31px;

          .txt {
            padding-top: 17px;
          }
        }
      }
    }
  }
  &__box {
    text-align: center;
    padding: 113px 0 85px;
    &--txt {
      font-size: 16px;
      margin: 11px 0 36px;
      position: relative;
      z-index: 2;
      line-height: 26px;
    }
    &--link {
      max-width: 628px;
      width: 100%;
      margin: -45px auto -84px;
      background: #fff;
      padding: 40px 40px 47px;
      z-index: 1;
      position: relative;
    }
    .aboutus__hd {
      letter-spacing: 0;
      font-size: 32px;
    }
    .top__cmbtn {
      max-width: 260px;
      width: 100%;
      position: relative;
      right: 8px;
    }
  }
  .top__lineup {
    .top__blockhd {
      letter-spacing: -2px;
      position: relative;
      right: 4px;
    }
    &--subtxt {
      text-align: center;
      font-size: 16px;
      margin: 23px 0 67px;
      position: relative;
      letter-spacing: -1px;
      right: 4px;
    }
    &--inner {
      margin-bottom: 35px;
    }
    &--content {
      margin-bottom: 62px;
      .top__cmbtn {
        margin: 0 auto;
        display: block;
        max-width: 262px;
        position: relative;
        left: 2px;
      }
      &:nth-child(3) {
        .top__cmbtn {
          margin: 41px auto 24px;
        }
      }
      &:nth-child(4) {
        .top__cmbtn {
          margin: 41px auto 24px;
        }
      }
    }
    .link {
      padding-top: 39px;
    }
    .about-catch {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      line-height: 22px;
      letter-spacing: 1px;
    }
    .title {
      line-height: 28px;
      margin: 16px 0 35px;
      letter-spacing: 1.5px;
    }
  }
  @media (max-width: 767px) {
    &__hd {
      font-size: 21px !important;
      letter-spacing: 0.3px;

      small {
        font-size: 16px;
        margin-bottom: 3px;
      }
    }
    &__mainimg {
      margin: -37px 0 31px;
      height: 423px;
      background: url(../img/aboutus/img_about01_sp.jpg) no-repeat bottom center;
      background-size: contain;
      background-color: #efefef;
      &--txt {
        padding-top: 55px;
        strong {
          font-size: 21px;
          letter-spacing: -0.5px;
          margin-bottom: 13px;
        }
        p {
          font-size: 15px;
          text-align: justify;
          line-height: 1.7;
          padding: 0 2px;
        }
      }
    }
    &__block01 {
      .aboutus__hd {
        background-size: 26px 38px;
        padding-top: 54px;
        margin-bottom: 26px;
      }
      ul {
        margin-left: -20px;
        margin-right: -20px;
        li {
          padding: 32px 0 37px;
          &:nth-child(2) {
            img {
              margin-bottom: 14px !important;
            }
            .txt {
              margin-bottom: 19px;
            }
          }
          &:nth-child(3) {
            .txt {
              margin-bottom: 20px;
              strong {
                margin-bottom: 23px;
              }
            }
          }
          .txt,
          .img {
            width: 100%;
            padding: 0;
            float: none;
          }
          .txt {
            margin-bottom: 17px;
            span {
              position: static;
              font-size: 15px;
              padding-bottom: 0;
              margin-bottom: 17px;
            }
            strong,
            p {
              padding-left: 15px;
              padding-right: 15px;
            }
            strong {
              font-size: 18px;
              line-height: 1.35;
              margin-bottom: 20px;
            }
            p {
              font-size: 13px;
              line-height: 1.65;
              letter-spacing: -0.5px;
            }
          }
          .img {
            padding: 0 15px;

            img {
              margin-bottom: 8px;
            }
          }
          &:nth-child(2) {
            padding-top: 40px;
            padding-bottom: 34px;
          }
          &:last-child {
            padding-top: 15px;
            padding-bottom: 33px;
          }
        }
      }
      &--bot {
        margin-left: -20px;
        margin-right: -20px;
        padding: 50px 40px 57px;
        font-size: 21px;
        line-height: 1.4;
        letter-spacing: 1px;

        &::before {
          width: 12px;
          height: 22px;
        }
        small {
          font-size: 16px;
          margin-bottom: 1px;
        }
      }
    }
    &__block02 {
      padding: 65px 0 23px;
      .aboutus__hd {
        background-size: 32px 30px;
        margin-bottom: 7px;
        padding-top: 42px;
      }
      &--desc {
        font-size: 15px;
        line-height: 1.45;
        letter-spacing: -0.5px;
      }
      &--steps {
        margin: 27px -20px 74px;
      }
      ul {
        margin-left: -5px;
        margin-right: -5px;

        li {
          margin-bottom: 41px;

          &::before {
            display: none;
          }
          .img,
          .txt {
            width: 100%;
            float: none;
          }
          .img {
            position: static;
          }
          .txt {
            padding: 60px 20px 14px;
            &::before {
              display: none;
            }
            img {
              width: 67px;
              height: 67px;
              top: -18px;
            }
            strong {
              font-size: 18px;
              letter-spacing: -0.5px;
              margin-bottom: 12px;
            }
            p {
              font-size: 13px;
            }
          }
        }
      }
    }
    &__block03 {
      padding-top: 49px;
      margin-bottom: 3px;

      .aboutus__hd {
        background-size: 16px 33px;
        padding-top: 47px;
        margin-bottom: 31px;
      }
      ul {
        margin-left: -10px;
        margin-right: -10px;

        li {
          width: 100%;
          float: none;
          margin-bottom: 54px;

          .txt {
            margin-top: -21px;

            span {
              font-size: 36px;
              padding-bottom: 7px;
              margin-bottom: 13px;
            }
            strong {
              font-size: 18px;
              line-height: 1.35;
              margin-bottom: 12px;
            }
            p {
              font-size: 13px;
              line-height: 1.5;
              padding: 0 22px;
              letter-spacing: -0.5px;
              margin-bottom: 21px;
            }
            a {
              &::before {
                width: 11px;
                height: 6px;
                right: -15px;
              }
            }
          }
        }
      }
    }
    &__box {
      padding: 64px 0 50px;
      .aboutus__hd {
        font-size: 18px !important;
      }
      &--txt {
        font-size: 13px;
        text-align: left;
        padding: 0 15px;
        margin: 9px 0 36px;
        line-height: 21px;
      }
      &--link {
        padding: 43px 44px 28px;
        margin-bottom: 0;
      }
      .img {
        padding: 0 15px;
      }
    }
    .top__lineup {
      padding: 0;
      &--content {
        margin-bottom: 17px;
      }
      &--inner {
        margin-bottom: 29px;
      }
      .top__blockhd {
        font-size: 18px;
        background-size: 50px 23px;
        right: auto;
      }
      &--subtxt {
        margin: 11px 0 31px;
        font-size: 13px;
        right: auto;
        letter-spacing: 0.5px;
        text-align: left;
        line-height: 21px;
      }
      .txt {
        left: auto;
      }
      .link {
        padding: 18px 0 10px;
      }
      .title {
        margin-top: 10px;
      }
      .about-catch {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
