@charset 'UTF-8';

.company {

  &__hd {
    font-weight: bold;
    font-size: 34px;
    background-repeat: no-repeat;
    background-position: top center;
    text-align: center;
    display: block;
    letter-spacing: 3px;
  }
  &__content {
    max-width: 840px;
    padding: 0 20px;
    margin: auto;
  }
  &__block01 {
    text-align: center;
    padding-top: 60px;
    margin-bottom: 92px;

    a {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: 16px;
      border: 1px solid #080404;
      width: 219px;
      height: 43px;
      line-height: 42px;
      margin: 0 12px;
      letter-spacing: 2px;

      &::before {
        content: "";
        background: url(../img/company/ico_ar_down.svg) no-repeat center center;
        background-size: 100% 100%;
        width: 10px;
        height: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -13px;
      }
    }
  }
  &__block02 {
    background: #f1f1f1;
    padding: 118px 0 141px;

    .company__hd {
      background-image: url(../img/company/ico_company01.svg);
      background-size: 35px 45px;
      padding-top: 69px;
      margin-bottom: 57px;
    }
    &--char {
      @include clearfix();
      margin-bottom: 59px;

      > img {
        float: left;
      }
      .txt {
        overflow: hidden;
        padding: 58px 0 0 57px;

        strong, small, span {
          display: block;
          letter-spacing: 1px;
        }
        strong {
          font-size: 26px;
          margin-bottom: 25px;
        }
        small {
          margin-bottom: 1px;
        }
        span {
          letter-spacing: 1.5px;
          font-size: 20px;
        }
      }
    }
    &--desc {
      font-size: 16px;
      line-height: 2.15;
      text-align: justify;
    }
  }
  &__block03 {
    padding: 150px 0 0;
    margin-bottom: 115px;

    .company__hd {
      background-image: url(../img/company/ico_company02.svg);
      background-size: 35px 25px;
      padding-top: 44px;
      margin-bottom: 64px;
    }
    &--add {
      @include clearfix();
      margin-bottom: 55px;

      img {
        float: left;
        width: 317px;
      }
      .txt {
        overflow: hidden;
        padding: 19px 0 0 38px;

        strong {
          display: block;
          font-size: 18px;
          margin-bottom: 16px;
        }
        p {
          line-height: 1.75;
          letter-spacing: 1px;
        }
      }
    }
    &--map {
      margin-bottom: 129px;

      .map_frame {
        height: 514px;
        margin-bottom: 23px;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .map_link {
        text-align: right;
        padding-right: 18px;
        letter-spacing: 1px;

        a {
          display: inline-block;
          text-decoration: underline;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            right: -16px;
            top: 50%;
            margin-top: -3px;
            background-size: 100% 100%;
            background-image: url(../img/company/ico_ar_right.png);
            background-repeat: no-repeat;
            background-position: center center;
            width: 12px;
            height: 6px;
          }
        }
      }
    }
    &--table {
      border: 1px solid #080404;

      table {

        tr:last-child {
          td,th {
            border: 0;
          }
        }
        th, td {
          font-size: 16px;
          vertical-align: top;
          border-bottom: 1px solid #080404;
          font-weight: normal;
          text-align: left;
          padding: 22px 23px 23px;
          line-height: 1.65;
        }
        th {
          background: #f1f1f1;
          width: 244px;
        }
        td {
          padding-left: 26px;
          letter-spacing: 1px;
        }
      }
    }
  }
  @media (max-width:767px) {

    &__hd {
      font-size: 21px;
      letter-spacing: 1.5px;
    }
    &__content {
      padding: 0 15px;
    }
    &__block01 {
      padding: 18px 15px 0;
      margin-bottom: 44px;

      &--btn {
        @include clearfix();
      }
      a {
        font-size: 14px;
        height: 52px;
        line-height: 51px;
        float: left;
        margin: 0;
        width: 48%;
        letter-spacing: 1px;

        &::before {
          width: 12px;
          height: 23px;
        }
        &:last-child {
          float: right;
        }
      }
    }
    &__block02 {
      padding: 46px 0 66px;

      .company__hd {
        background-size: 22px 28px;
        padding-top: 36px;
        margin-bottom: 41px;
      }
      &--char {
        margin-bottom: 33px;

        img {
          width: 90px;
        }
        .txt {
          padding: 0 0 0 21px;
          position: relative;
          top: -1px;

          strong {
            font-size: 16px;
            line-height: 1.35;
            margin-bottom: 11px;
          }
          small, span {
            padding-left: 2px;
          }
          small {
            font-size: 11px;
            margin-bottom: 2px;
          }
          span {
            font-size: 13px;
            letter-spacing: 1px;
          }
        }
      }
      &--desc {
        font-size: 13px;
        line-height: 1.65;
        letter-spacing: 0.5px;
      }
    }
    &__block03 {
      margin-bottom: 78px;
      padding: 80px 0 0;

      .company__hd {
        background-size: 25px 18px;
        padding-top: 29px;
        margin-bottom: 34px;
      }
      &--add {
        margin-bottom: 45px;

        img, .txt {
          width: 100%;
        }
        img {
          display: block;
          float: none;
          margin-bottom: 19px;
        }
        .txt {
          padding: 0 2px;

          strong {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.7px;
            margin-bottom: 13px;
          }
          p {
            font-size: 13px;
            margin-top: 14px;
            line-height: 1.55;
          }
        }
      }
      &--map {
        margin-bottom: 60px;

        .map_frame {
          height: 185px;
          margin-bottom: 10px;
        }
        .map_link {
          padding-right: 20px;
          letter-spacing: 1.2px;

          a {

            &::before {
              background-image: url(../img/company/ico_ar_right_sp.png);
              width: 11px;
              height: 6px;
              right: -19px;
            }
          }
        }
      }
      &--table {

        table {

          td, th {
            font-size: 13px;
            line-height: 1.35;
            padding: 11px 10px 7px 9px;
            letter-spacing: 0;
          }
          th {
            width: 98px;
          }
          td {
            padding-left: 12px;
          }
        }
      }
    }
  }
}
