@charset 'UTF-8';

.comfortable {

  &__block01 {
    text-align: center;
    padding-top: 66px;
    font-size: 0;
    margin-bottom: 145px;

    > img {
      width: 100%;
      display: block;
      margin-bottom: 87px;
    }
    strong {
      font-size: 28px;
      display: block;
      line-height: 1.2;
      letter-spacing: 0.5px;
      margin-bottom: 17px;
    }
    p {
      font-size: 16px;
      line-height: 2.15;
      margin-bottom: 56px;
    }
    a {
      display: inline-block;
      vertical-align: top;
      position: relative;
      font-size: 16px;
      border: 1px solid #080404;
      width: 219px;
      height: 43px;
      line-height: 42px;
      margin: 0 13px;
      letter-spacing: 2px;

      &::before {
        content: "";
        background: url(../img/comfortable/ico_ar_down.svg) no-repeat center center;
        background-size: 100% 100%;
        width: 10px;
        height: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -13px;
      }
    }
  }
  &__cmblock {

    &--hd {
      height: 551px;
      background: #f1f1f1;
      text-align: center;
      font-weight: bold;
      font-size: 30px;
      padding-top: 98px;

      span {
        display: inline-block;
        background-repeat: no-repeat;
        background-position: top center;
        letter-spacing: 3px;
      }
    }
    &--img {
      margin: -271px auto 72px;

      img {
        display: block;
        width: 100%;
      }
    }
    &--subhd {
      display: block;
      text-align: center;
      font-size: 22px;
      line-height: 1.5;
      margin-bottom: 33px;
    }
    &--desc {
      text-align: center;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
  &__block02 {
    margin-bottom: 208px;

    .comfortable__cmblock--hd {

      span {
        background-image: url(../img/comfortable/ico_comfortable01.svg);
        background-size: 15px 40px;
        padding-top: 67px;
      }
    }
    ul {
      margin: 71px 0 0;

      li {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        border-bottom: 1px dashed #080404;
        padding-bottom: 110px;
        margin-bottom: 66px;

        img {
          width: 47%;
          float: left;
        }
        .txt {
          overflow: hidden;
          position: relative;
          top: -4px;
          padding-left: 70px;

          strong {
            display: block;
            text-align: center;
            font-size: 26px;
            line-height: 1.35;
            margin-bottom: 21px;
          }
          p {
            font-size: 16px;
            text-align: justify;
            line-height: 1.65;

            em {
              font-weight: bold;
            }
          }
        }
        &:first-child {

          img {
            width: 44.7%;
          }
          .txt {
            top: -3px;
            padding-left: 44px;
          }
        }
        &:nth-child(2) {
          padding-bottom: 51px;
          margin-bottom: 59px;
        }
        &:nth-child(3) {
          padding-bottom: 67px;
          margin-bottom: 59px;
        }
        &:nth-child(4) {
          padding-bottom: 67px;
          margin-bottom: 59px;
        }
        &:nth-child(5) {
          padding-bottom: 49px;
          margin-bottom: 59px;
        }
        &:nth-child(6) {
          padding-bottom: 43px;
          margin-bottom: 77px;
        }
        &:last-child {
          padding-bottom: 50px;
          margin-bottom: 0;
        }
      }
    }
  }
  &__block03 {
    margin-bottom: 143px;

    .comfortable__cmblock--hd {

      span {
        background-image: url(../img/comfortable/ico_comfortable02.svg);
        background-size: 37px 38px;
        padding-top: 67px;
      }
    }
    .comfortable__cmblock--subhd {
      margin-bottom: 46px;
    }
    &--desc {
      max-width: 798px;
      margin: 0 auto 111px;
      font-size: 16px;
      text-align: justify;
    }
    &--btn {
      text-align: center;

      em, a {
        display: inline-block;
        vertical-align: middle;
      }
      em {
        letter-spacing: 1.5px;
        margin-right: 33px;
      }
      a {
        padding: 13px 27px 14px;
      }
    }
  }
  @media (min-width:768px) and (max-width:960px) {
    &__block02 {
      ul {
        li {
          .txt {
            padding-left: 30px;

            strong {
              font-size: 23px;
            }
          }
        }
      }
    }
  }
  @media (max-width:767px) {
    &__cmblock {

      &--hd {
        font-size: 21px;
        padding-top: 28px;
        height: 130px;

        span {
          letter-spacing: 1px;
        }
      }
      &--img {
        margin: 0 -15px;
      }
      &--subhd {
        font-size: 17px;
        margin-top: 28px;
        line-height: 1.55;
        margin-bottom: 26px;
        letter-spacing: 1px;
      }
      &--desc {
        font-size: 13px;
        text-align: justify;
      }
      .wcm {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    &__block01 {
      padding: 0;
      margin-bottom: 87px;

      > img {
        margin-bottom: 41px;
      }
      &--content {
        padding: 0 15px;
      }
      &--btn {
        @include clearfix();
      }
      strong {
        font-size: 17px;
        line-height: 1.55;
        margin-bottom: 26px;
      }
      p {
        font-size: 13px;
        text-align: justify;
        line-height: 1.65;
        letter-spacing: 0.5px;
        margin-bottom: 53px;
      }
      a {
        font-size: 14px;
        height: 51px;
        line-height: 51px;
        float: left;
        margin: 0;
        width: 48%;

        &::before {
          width: 12px;
          height: 23px;
        }
        &:last-child {
          float: right;
        }
      }
    }
    &__block02 {
      margin-bottom: 93px;

      .comfortable__cmblock--hd {

        span {
          background-size: 12px 31px;
          padding-top: 40px;
        }
      }
      ul {
        margin: 20px -15px 0;

        li {
          border: 0;
          padding-bottom: 0 !important;
          margin-bottom: 68px;

          img {
            width: 100% !important;
            float: none;
            margin-bottom: 18px;
          }
          .txt {
            position: static;
            padding: 0 15px !important;

            strong {
              font-size: 17px;
              line-height: 1.55;
              letter-spacing: 0.5px;
              margin: 9px 0 23px;
            }
            p {
              text-align: left;
              font-size: 13px;
              letter-spacing: 0.5px;
            }
          }
          &:first-child {
            padding: 0 15px;

            .txt {
              padding: 0 !important;
            }
          }
          &:nth-child(2) {
            margin-bottom: 68px;
          }
          &:nth-child(3) {
            margin-bottom: 69px;
          }
          &:nth-child(4) {
            margin-bottom: 67px;
          }
          &:nth-child(5) {
            margin-bottom: 72px;
          }
          &:nth-child(6) {
            margin-bottom: 80px;
          }
        }
      }
    }
    &__block03 {
      margin-bottom: 96px;
      
      .comfortable__cmblock--hd {

        span {
          background-size: 23px 24px;
          padding-top: 39px;
        }
      }
      .comfortable__cmblock--subhd {
        margin-bottom: 26px;
        margin-top: 48px;
      }
      &--desc {
        line-height: 1.65;
        font-size: 13px;
        text-align: left;
        letter-spacing: 0.5px;
        margin-bottom: 87px;
      }
      &--btn {

        em {
          display: block;
          margin: 0 0 20px;
          text-align: center;
          font-size: 17px;
        }
        a {
          padding: 8px 47px 5px;
          line-height: 1.4;
        }
      }
    }
  }
}
