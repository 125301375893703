@charset 'UTF-8';

.privacypolicy {

  &__content {
    max-width: 842px;
    padding: 0 20px;
    margin: 86px auto 167px;
    font-size: 16px;
    line-height: 1.7;
    letter-spacing: 0.9px;

    &--intro {
      margin-bottom: 57px;
    }
    ul {

      li {
        border-bottom: 1px dashed #080404;
        padding-bottom: 42px;
        margin-bottom: 45px;

        strong {
          display: block;
          position: relative;
          padding-left: 17px;
          margin-bottom: 6px;

          &::before {
            content: "";
            position: absolute;
            top: 9px;
            left: 0;
            width: 7px;
            height: 7px;
            background: #080404;
            @include border-radius(50%);
          }
        }
      }
    }
  }
  @media (max-width:767px) {

    &__content {
      padding: 0 15px;
      font-size: 13px;
      margin: 15px auto 77px;
      line-height: 1.65;
      letter-spacing: 0.8px;

      &--intro {
        margin-bottom: 37px;
      }
      ul {

        li {
          padding-bottom: 30px;
          margin-bottom: 26px;

          strong {
            padding-left: 16px;
            letter-spacing: 0.7px;
            margin-bottom: 5px;

            &::before {
              width: 5px;
              height: 5px;
              top: 8px;
              left: 5px;
            }
          }
        }
      }
    }
  }
}
