@charset 'UTF-8';

.voice {
  &__archive {
    padding-top: 61px;
    margin-bottom: 128px;
    overflow: hidden;
    &--list {
      font-size: 0;
      margin-left: -7px;
      margin-right: -7px;
      .item {
        width: 25%;
        display: inline-block;
        vertical-align: top;
        padding: 0 7px;
        @include transition(all 0.3s);
        margin-bottom: 68px;
        img {
          width: 100%;
        }
        &__title {
          display: block;
          font-size: 16px;
          text-decoration: underline;
          line-height: 1.5;
          margin-bottom: 13px;
          a {
            opacity: 1;
          }
        }
        &__content {
          letter-spacing: 0.5px;
          padding: 15px 16px 12px;
          span {
            font-size: 14px;
            display: block;
          }
        }
        &__cat {
          display: inline-block;
          p {
            border: 1px solid #000;
            font-size: 12px;
            text-align: center;
            margin: 10px 2px 0;
            padding: 0 10px;
            display: inline-block;
            @include SP {
              width: auto;
              padding: 0 5px;
            }
          }
        }
        &:hover {
          opacity: 0.5;
          .item__content {
            background: #f1f1f1;
          }
        }
      }

      img {
        width: 240px;
        height: 240px;
      }
    }
    .wp-pagenavi {
      margin-top: 89px;
    }
    @media (max-width: 767px) {
      padding-top: 18px;
      margin-bottom: 53px;
      &--list {
        margin: 0;
        .item {
          width: 100%;
          padding: 0 0 17px;
          margin-bottom: 25px;
          border-bottom: 1px dashed #080404;
          img {
            float: left;
            width: 100px;
            height: 100px;
            margin-right: 18px;
          }
          &__title {
            font-size: 14px;
            margin-bottom: 4px;
          }
          &__content {
            position: relative;
            top: -1px;
            padding: 0 0 14px;
            overflow: hidden;
            background: url(../img/common/icon/ico_ar_right_gray.png) no-repeat bottom left;
            background-size: 11px 6px;
            span {
              font-size: 12px;
            }
          }
        }
      }
      .wp-pagenavi {
        margin-top: 54px;
      }
      .cat {
        width: auto;
        margin: 8px 0 0 0;
        display: inline-block;
        padding: 0 5px;
      }
    }
  }
  &__detail {
    padding-top: 63px;
    margin-bottom: 136px;

    &--wrapper {
      max-width: 840px;
      margin: auto;
      padding: 0 15px;
    }
    &--intro {
      @include clearfix();
      margin-bottom: 50px;

      img {
        width: 400px;
        float: left;
        margin-right: 41px;
      }
      .txt {
        overflow: hidden;
        padding: 18px 0;

        strong,
        span {
          display: block;
        }
        strong {
          line-height: 1.55;
          font-size: 26px;
          margin-bottom: 21px;
        }
        span {
          letter-spacing: 2.8px;
          padding-left: 6px;
        }
      }
    }
    &--content {
      font-size: 16px;
      margin-bottom: 63px;

      p {
        margin-bottom: 30px;
        line-height: 2.15;
        letter-spacing: 0.5px;
      }
    }
    &--qa {
      background: #f1f1f1;
      padding: 104px 0 63px;
      margin-bottom: 155px;

      ul {
        li {
          margin-bottom: 68px;

          strong {
            display: block;
            border-left: 4px solid #080404;
            font-size: 20px;
            padding: 1px 0 0px 20px;
            line-height: 1.5;
            margin-bottom: 21px;
          }
          p {
            font-size: 16px;
            padding-left: 4px;
          }
        }
      }
    }
    &--navi {
      font-size: 0;

      .prev,
      .next,
      .list {
        display: inline-block;
        vertical-align: top;

        a {
          text-decoration: underline;
          font-size: 14px;
        }
      }
      .list {
        width: 23.8%;
        text-align: center;
        padding-top: 88px;
      }
      .prev,
      .next {
        width: 38.1%;

        .ct {
          position: relative;

          &::before {
            font-family: "Roboto", sans-serif;
            font-size: 18px;
            position: absolute;
            top: 92px;
            background-repeat: no-repeat;
            background-size: 22px 11px;
            letter-spacing: 2.5px;
          }
          img {
            width: 100%;
            margin-bottom: 14px;
          }
          a {
            line-height: 1.75;
            display: block;
            padding: 0 2px;
            letter-spacing: -0.5px;
          }
        }
      }
      .prev {
        .ct {
          padding-left: 107px;

          &::before {
            left: 0;
            content: "PREV";
            background-image: url(../img/common/icon/ico_prev.svg);
            background-position: center left;
            padding-left: 28px;
          }
        }
      }
      .next {
        .ct {
          padding-right: 107px;

          &::before {
            right: 0;
            content: "NEXT";
            background-image: url(../img/common/icon/ico_next.svg);
            background-position: center right;
            padding-right: 28px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: 20px;
      margin-bottom: 57px;

      &--intro {
        margin-bottom: 28px;

        img {
          float: none;
          width: 100%;
          margin: 0;
        }
        .txt {
          padding: 12px 0;

          strong {
            font-size: 17px;
            margin-bottom: 10px;
          }
          span {
            font-size: 11px;
            padding-left: 1px;
            letter-spacing: 2.1px;
          }
        }
      }
      &--content {
        font-size: 13px;
        margin-bottom: 29px;

        p {
          margin-bottom: 20px;
          line-height: 1.65;
        }
      }
      &--qa {
        padding: 50px 0 13px;
        margin-bottom: 60px;

        .voice__detail--wrapper {
          padding-left: 13px;
          padding-right: 13px;
        }
        ul {
          li {
            margin-bottom: 43px;

            strong {
              border-width: 3px;
              font-size: 15px;
              line-height: 1.3;
              padding: 3px 0 0 19px;
              margin-bottom: 16px;
            }
            p {
              font-size: 13px;
              line-height: 1.65;
              padding-left: 3px;
            }
          }
        }
      }
      &--navi {
        padding: 0 15px;
        overflow: hidden;
        text-align: center;

        .voice__detail--wrapper {
          padding: 0;
          margin-left: -20px;
          margin-right: -20px;
        }
        .prev,
        .next {
          width: 50%;
          padding: 0 20px;

          .ct {
            padding: 26px 0 0;

            &::before {
              background-size: 11px 6px;
              font-size: 13px;
              font-weight: bold;
              top: 0;
              line-height: 1;
              letter-spacing: 2px;
            }
            img {
              margin-bottom: 7px;
            }
            a {
              font-size: 12px;
              padding: 0;
              letter-spacing: 0.5px;
              text-align: left;
            }
          }
        }
        .prev {
          .ct {
            &::before {
              padding-left: 18px;
            }
          }
        }
        .next {
          .ct {
            &::before {
              padding-right: 13px;
            }
          }
        }
        .top__cmbtn {
          margin-top: 54px;
          padding: 15px 60px 14px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
