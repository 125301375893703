@charset 'UTF-8';

.top {
  &__cmbtn {
    text-align: center;
    background: #fff;
    border: 1px solid #080404;
    position: relative;
    display: inline-block;
    margin: auto;
    letter-spacing: 0.5px;
    font-size: 14px;
    padding: 13px 65px 14px;

    span {
      position: relative;
      z-index: 2;
    }
    &::before {
      content: "";
      width: 22px;
      height: 11px;
      background: url(../img/top/ico_ar_right.svg) no-repeat center center;
      background-size: 100% 100%;
      position: absolute;
      top: 50%;
      margin-top: -7px;
      right: -14px;
    }
  }
  &__blockhd {
    display: block;
    text-align: center;
    position: relative;
    font-weight: bold;
    font-size: 34px;
    background-repeat: no-repeat;
    background-position: top center;
    letter-spacing: -1.5px;
  }
  &__mainimg {
    height: 619px;
    background: url(../img/top/img_top01.png) no-repeat center center;
    background-size: cover;
    position: relative;
    &--content {
      position: relative;
      top: 84px;
      padding-left: 14px;
      small,
      strong {
        display: block;
      }
      small {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.75;
        letter-spacing: 1px;
        margin-bottom: 48px;
      }
      i {
        font-style: normal;
        position: relative;
        font-feature-settings: "palt";
        margin: 0 12px 0 0;
      }
      strong {
        font-size: 36px;
        line-height: 1.65;
        margin-bottom: 16px;
        letter-spacing: 1.2px;
      }
      .top__cmbtn {
        padding: 9px 29px 10px;
        margin: 0 0 0 5px !important;
        max-width: 220px !important;
        &::before {
          background-size: 17px 9px;
          right: -14px;
        }
      }
    }
  }
  &__event,
  &__interview {
    background: #f1f1f1;
  }
  &__event {
    padding-bottom: 55px;
    .top__blockhd {
      background-image: url(../img/top/ico_event.svg);
      background-size: 34px 42px;
      margin-top: -14px;
      padding-top: 63px;
      margin-bottom: 9px;
    }
    &--subhd {
      text-align: center;
      font-size: 16px;
      letter-spacing: -0.3px;
    }
    &--content {
      @include clearfix();
      a {
        display: block;
      }
      // img {
      //   width: 311px;
      //   height: 220px;
      // }
      .txt {
        overflow: hidden;
        padding: 13px 0 0;
        font-size: 0;
        .cat,
        .begin_date,
        .end_date {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          letter-spacing: 1.8px;
        }
        .link {
          position: relative;
          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          &:hover {
            .title {
              opacity: 0.5;
            }
          }
        }
        .cat {
          font-size: 12px;
          border: 1px solid #080404;
          padding: 3px 4px 3px 4px;
          letter-spacing: 1.1px;
          margin: 0 5px 5px 0;
          &:last-child {
            margin: 0;
          }
        }
        .date {
          display: flex;
          margin: 10px 0 0;
        }
        .begin_date,
        .end_date {
          i {
            font-style: normal;
            font-feature-settings: "palt";
          }
        }
        // .end_date {
        //   margin-left: 14px;
        // }
        .begin_date {
          margin-right: 14px;
        }
        .title {
          font-size: 18px;
          text-decoration: underline;
          font-weight: bold;
          margin: 15px 0 35px;
          line-height: 1.8;
          text-align: justify;
          letter-spacing: 1.5px;
          @include transition(all 0.3s);
        }
      }
    }
    &--inner {
      max-width: 1040px;
      width: 100%;
      margin: 64px auto 0;
      display: flex;
      padding: 0;
      justify-content: center;
    }
    .top__cmbtn {
      max-width: 260px;
      width: 100%;
      margin: 24px auto;
      display: block;
      padding-left: 0;
      padding-right: 0;
      position: relative;
      right: 6px;
    }
  }
  &__works {
    overflow: hidden;
    text-align: center;
    padding: 102px 0 0;
    margin-bottom: 100px;
    .top__blockhd {
      background-image: url(../img/top/ico_works.svg);
      background-size: 42px 38px;
      padding-top: 52px;
      letter-spacing: 3px;
    }
    &--content {
      font-size: 0;
      margin: 48px -3px 83px;
      li {
        width: 25%;
        display: inline-block;
        vertical-align: top;
        padding: 0 3px;
        font-size: 14px;
        text-align: left;
        @include transition(all 0.3s);
        &:hover {
          opacity: 0.5;
        }
        img {
          width: 100%;
          display: block;
          margin-bottom: 19px;

          // width: 345px;
          // height: 450px;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          text-decoration: underline;
          letter-spacing: 0.2px;
          margin-bottom: 7px;
          padding: 0 17px;
          a:hover {
            opacity: 1;
          }
        }
        span {
          padding: 0 15px;
          letter-spacing: 0.5px;
          display: block;
        }
      }
    }
  }
  &__interview {
    overflow: hidden;
    padding: 82px 0 117px;
    text-align: center;

    .top__blockhd {
      background-image: url(../img/top/ico_interview.svg);
      background-size: 40px 35px;
      padding-top: 54px;
      letter-spacing: -0.8px;
      margin-bottom: 69px;
    }
    .top__cmbtn {
      padding: 13px 57px 14px;
    }
    &--content {
      margin: 0 -27px 60px;
      font-size: 0;

      li {
        padding: 0 27px;
        display: inline-block;
        vertical-align: top;
        width: 25%;
        text-align: justify;
        font-size: 14px;

        img {
          display: block;
          margin-bottom: 10px;
          // width: 210px;
          // height: 210px;
        }
        h3 {
          font-size: 16px;
          text-decoration: underline;
          margin-bottom: 11px;
        }
        span {
          letter-spacing: 0.5px;
        }
      }
    }
  }
  &__column {
    padding: 160px 0 0;
    margin-bottom: 153px;
    text-align: center;

    .top__blockhd {
      background-image: url(../img/top/ico_column.svg);
      background-size: 37px 43px;
      padding-top: 67px;
    }
    .top__cmbtn {
      padding: 13px 72px 14px;
    }
    &--content {
      max-width: 840px;
      padding: 0 20px;
      margin: 81px auto 30px;

      li {
        width: 100%;
        display: inline-block;
        border-bottom: 1px dashed #080404;
        margin-bottom: 10px;
        padding-bottom: 26px;
        margin-bottom: 24px;

        img {
          width: 100px;
          float: left;
          // height: 100px;
        }
        .txt {
          text-align: justify;
          padding-left: 28px;
          overflow: hidden;
          position: relative;
          top: -3px;

          span {
            font-family: "Roboto", sans-serif;
            letter-spacing: 1.7px;
            display: block;
            margin-bottom: 2px;
          }
          .title {
            font-size: 18px;
            text-decoration: underline;
            letter-spacing: 1px;
            margin-bottom: 18px;
            @include transition(all 0.3s);

            &:hover {
              opacity: 0.5;
            }
          }
          a {
            position: relative;
            text-decoration: underline;
            padding-right: 18px;
            display: inline-block;
            margin-left: 4px;

            &::before {
              content: "";
              background: url(../img/top/ico_ar_right.svg) no-repeat center center;
              background-size: 100% 100%;
              width: 11px;
              height: 6px;
              position: absolute;
              top: 8px;
              right: 0;
            }
          }
        }
      }
    }
  }
  &__seo {
    background: url(../img/top/bg_seo.jpg) no-repeat center center;
    background-size: cover;
    @include PC {
      min-height: 416px;
    }
    &--content {
      padding: 103px 20px 102px;
      max-width: 840px;
      margin: 0 auto;

      strong {
        font-size: 18px;
        display: block;
        letter-spacing: 1px;
      }
      p {
        margin-top: 15px;
        line-height: 1.9;
        letter-spacing: -0.5px;
      }
      a,
      p {
        font-size: 15px;
      }
      a {
        display: inline;
      }
    }
  }
  &__lineup {
    padding: 34px 0 0;
    .top__blockhd {
      background-image: url(../img/top/ico_news.svg);
      background-size: 99px 27px;
      padding-top: 40px;
      letter-spacing: 0;
    }
    &--subtxt {
      text-align: center;
      font-size: 14px;
      margin: 22px 0 70px;
    }
    &--inner {
      max-width: 1300px;
      width: 100%;
      margin: 0 auto 56px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @include PC {
        padding: 0 20px;
      }
    }
    &--content {
      max-width: 48.6%;
      width: 100%;
      margin: 0 0 40px;
      position: relative;
      &:nth-child(3) {
        .top__cmbtn {
          margin: 29px auto 24px;
        }
      }
      &:nth-child(4) {
        .top__cmbtn {
          margin: 29px auto 24px;
        }
      }
    }
    .txt {
      position: relative;
      top: -76px;
      background: #fff;
      z-index: 3;
      max-width: 80.68%;
      width: 100%;
      margin: 0 auto -76px;
    }
    .link {
      padding: 34px 20px 10px;
    }
    .cat {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
    .catch {
      font-size: 14px;
      text-align: center;
    }
    .title {
      font-size: 34px;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      margin: 14px 0 20px;
      letter-spacing: 2px;
    }
    .point-event {
      pointer-events: none;
      display: none;
    }
  }
  .top__cmbtn {
    max-width: 260px;
    width: 100%;
    margin: 24px auto;
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  @include PC {
    .top__event--content {
      max-width: 346px;
      width: 100%;
      margin: 0;
    }
  }
  @media (max-width: 767px) {
    padding-top: 0;
    &__cmbtn {
      font-size: 13px;
      padding: 15px 53px 14px;

      &::before {
        width: 22px;
        height: 11px;
        margin-top: -6px;
        right: -15px;
      }
    }
    &__blockhd {
      font-size: 22px;
    }
    &__mainimg {
      height: 402px;
      background: url(../img/top/img_top01_sp.png) no-repeat center center;
      background-size: cover;
      .top__cmbtn {
        padding: 8px 8px 7px;
        margin: 19px 0 0 0 !important;
        max-width: 183px !important;
        &::before {
          background-size: 16px 9px;
          right: -13px;
        }
      }
      &--content {
        top: 74px;
        padding-left: 15px;
        padding-right: 15px;
        small {
          font-size: 10px;
          margin-bottom: 7px;
        }
        strong {
          line-height: 1.55;
          font-size: 18px;
          margin-bottom: 14px;
        }
        i {
          margin: 0;
        }
      }
    }
    &__event {
      padding: 53px 0 53px;
      text-align: center;
      .top__blockhd {
        background-size: 22px 27px;
        margin: 0 0 0;
        padding-top: 42px;
        letter-spacing: -1px;
      }
      .top__cmbtn {
        max-width: 230px;
        margin: 35px auto 0;
      }
      &--subhd {
        font-size: 12px;
      }
      &--content {
        padding: 0 15px;
        margin-top: 29px;
        margin-bottom: 14px;
        display: flex;
        flex-wrap: wrap;
        img {
          width: 100%;
          height: auto;
          float: none;
        }
        .txt {
          padding: 0 0 0 17px;
          max-width: calc(100% - 132px);
          width: 100%;
          @include SP {
            max-width: 49%;
            padding: 0 0 0 10px;
          }
          .top__cmbtn {
            padding: 15px 26px 14px;
          }
          .info {
            @include clearfix();
            span {
              font-size: 12px;
              display: block;
              float: none;
            }
          }
          .cat {
            float: left;
            margin: 0 13px 2px 2px;
            padding: 4px 5px 5px;
            width: 92px;
          }
          .date {
            flex-wrap: wrap;
          }
          .begin_date,
          .end_date {
            overflow: hidden;
            display: block;
            letter-spacing: 2.5px;
            text-align: left;
            margin: 0;
          }
          .begin_date {
            position: relative;
            top: -2px;
            margin-bottom: -5px;
          }
        }
        .title {
          font-size: 15px;
          margin: 10px 0 0;
          line-height: 1.6;
          padding: 0 4px;
          letter-spacing: -0.5px;
          font-weight: bold;
          text-align: left;
          @include SP {
            width: 100%;
          }
        }
        a {
          display: block;
          max-width: 132px;
          width: 100%;
          height: 94px;
          overflow: hidden;
          @include SP {
            max-width: 50%;
          }
        }
      }
      &--inner {
        flex-wrap: wrap;
        margin-top: 0;
      }
    }
    &__works {
      padding: 44px 0 0;
      margin-bottom: 50px;
      overflow: hidden;
      .top__blockhd {
        background-size: 27px 25px;
        padding-top: 34px;
        letter-spacing: 0.5px;
      }
      .top__cmbtn {
        max-width: 230px;
        margin: 0 auto;
        position: relative;
        right: 8px;
        font-size: 14px;
        padding: 13px 15px 14px 26px;
        letter-spacing: 1px;
      }
      &--content {
        margin: 31px -2px 3px;
        li {
          width: 50%;
          padding: 0 2px;
          margin-bottom: 43px;

          .img-sp {
            // max-width: 158px;
            // width: 100%;
            // height: 206px;
            // overflow: hidden;
            // margin: 0 auto;
            img {
              margin-bottom: 10px;
              // width: 100%;
              // height: 100%;
            }
          }
          .title {
            font-size: 15px;
            line-height: 1.5;
            padding: 0 11px;
            margin-top: 10px;
            margin-bottom: 5px;
          }
          span {
            display: block;
            font-size: 12px;
            padding: 0 11px;
          }
        }
      }
    }
    &__lineup {
      .top__blockhd {
        background-size: 64px 25px;
        padding-top: 32px;
        font-size: 21px;
      }
      &--inner {
        margin-bottom: 30px;
      }
      &--content {
        overflow: hidden;
        margin-bottom: 8px;
        right: auto;
        max-width: 100%;
        text-align: center;
        position: relative;
        img {
          width: 100%;
        }
      }
      &--subtxt {
        margin: 15px 0 30px;
        text-align: left;
        padding: 0 15px;
        font-size: 12px;
      }
      .link {
        padding: 17px 20px 10px;
      }
      .txt {
        position: relative;
        top: -44px;
        left: 0;
        max-width: 225px;
        margin: 0 auto -30px;
        .top__cmbtn {
          display: none;
        }
      }
      .cat {
        font-size: 15px;
      }
      .title {
        font-size: 28px;
        margin: 4px 0 16px;
      }
      .catch {
        font-size: 13px;
      }
      .point-event {
        pointer-events: inherit;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__house {
      padding: 52px 0 70px;

      .top__blockhd {
        background-size: 16px 33px;
        padding-top: 47px;
        line-height: 1.35;
        letter-spacing: -0.2px;
      }
      &::before {
        height: 263px;
      }
      &::after {
        display: none;
      }
      &--content {
        margin-top: 43px;
        padding: 0 15px;

        li {
          height: auto;

          &:first-child {
            margin-bottom: 85px;
          }
          .bg {
            position: static;
            width: 100%;
            background: none;

            img {
              width: 100%;
              display: block;
            }
          }
          .txt {
            padding: 0;

            .frame {
              background: none;
              border: 0;
              width: 100%;
              height: auto;
              padding: 0 15px;

              .num {
                font-size: 36px;
                position: relative;
                top: -19px;
                padding-bottom: 6px;
                letter-spacing: 0px;
                margin-bottom: -10px;
              }
              .title {
                font-size: 18px;
                margin: 2px 0 13px;
                line-height: 1.35;
              }
              p {
                font-size: 13px;
                line-height: 1.5;
                margin-bottom: 21px;
              }
              a {
                &::before {
                  width: 11px;
                  height: 6px;
                  right: -5px;
                  top: 9px;
                }
              }
            }
          }
        }
      }
    }
    &__interview {
      padding: 56px 0 63px;
      .wcm {
        overflow: hidden;
      }
      .top__blockhd {
        background-size: 30px 26px;
        padding-top: 33px;
        letter-spacing: 0.5px;
        margin-bottom: 36px;
        margin-top: 0;
      }
      .top__cmbtn {
        padding: 14px 10px 14px;
        margin: -5px auto 0;
        position: relative;
        right: 7px;
        font-size: 14px;
        max-width: 232px;
      }
      .wcm {
        padding: 0 15px;
      }
      &--content {
        margin: 0 -12px 0;
        li {
          width: 50%;
          padding: 0 12px;
          margin-bottom: 45px;
          img {
            margin-bottom: 13px;
          }
          .title {
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.5px;
            margin-bottom: 7px;
            margin-top: 14px;
            margin: 14px 0 9px 5px;
          }
          span {
            font-size: 12px;
          }
        }
      }
      .img-sp {
        // width: 132px;
        // height: 132px;
        // overflow: hidden;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
    &__column {
      padding: 69px 0 0;
      margin-bottom: 69px;
      .wcm {
        overflow: hidden;
      }
      .top__blockhd {
        background-size: 29px 34px;
        padding-top: 47px;
        letter-spacing: -2.3px;
      }
      .top__cmbtn {
        padding: 14px 60px 15px;
        margin: 0 auto;
        max-width: 232px;
        position: relative;
        right: 7px;
      }
      &--content {
        max-width: 100%;
        padding: 0 15px;
        margin: 33px 0 12px;

        li {
          padding-bottom: 23px;
          margin-bottom: 22px;

          .txt {
            padding-left: 19px;
            top: -1px;

            span {
              font-size: 12px;
            }
            .title {
              font-size: 15px;
              margin-bottom: -1px;
              text-align: left;
            }
            a {
              font-size: 0;

              &::before {
                width: 11px;
                height: 6px;
                left: -6px;
                right: auto;
                top: 0;
              }
            }
          }
        }
      }
    }
    &__seo {
      background: url(../img/top/bg_seo_sp.jpg) no-repeat center center;
      background-size: cover;
      &--content {
        padding: 9px 31px 43px;
        text-align: justify;
        strong {
          font-size: 15px;
          line-height: 1.5;
          letter-spacing: 0.7px;
        }
        a,
        p {
          font-size: 13px;
        }
        p {
          margin-top: 14px;
          line-height: 1.65;
          letter-spacing: 0.6px;
        }
      }
    }
    .fb_iframe_widget iframe,
    .fb_iframe_widget_fluid span {
      width: 100% !important;
    }
  }
}
