@charset 'UTF-8';

/*-----------------------------------------------------------
Header
-------------------------------------------------------------*/
.header {
	position: relative;
	padding-top: 22px;
	&__main {
		@include clearfix();
		font-size: 0;

		&--logo {
			width: 23%;
			max-width: 223px;
			float: left;
			position: relative;
			top: -9px;
		}
		&--btn {
			float: right;
			width: 77%;
			text-align: right;

			a,
			.tel {
				display: inline-block;
				vertical-align: top;
			}
			.tel {
				width: 30%;
				max-width: 230px;
				font-size: 11px;
				line-height: 1;
				text-align: left;
				padding-top: 6px;

				span,
				small {
					display: block;
				}
				span {
					font-weight: bold;
					font-size: 28px;
					background: url(../img/common/icon/ico_tel.png) no-repeat center left;
					padding-left: 20px;
					letter-spacing: 1.3px;
					margin-bottom: 3px;
				}
				small {
					padding-left: 19px;
					letter-spacing: 1.3px;
				}
			}
			a {
				background-image: url(../img/common/icon/ico_ar_right_round_pc.png);
				background-position: center right 9px;
				background-repeat: no-repeat;
				height: 54px;
				max-width: 200px;
				width: 26%;
				text-align: left;
				font-size: 14px;
				line-height: 54px;
				padding-right: 30px;
				@include transition(all 0.3s);

				&:hover {
					opacity: 0.5;
				}
				span {
					font-weight: bold;
					color: #fff;
				}
				&.btn_event {
					background-color: #080404;
					padding-left: 31px;
					letter-spacing: -1.2px;
				}
				&.btn_contact {
					background-color: #d53230;
					margin-left: 6px;
					padding-left: 13px;
				}
			}
		}
	}
	&__menu {
		z-index: 999;

		&--pc {
			padding: 19px 0 15px;
			margin: auto;
			max-width: 1000px;
			overflow: hidden;

			ul {
				margin-left: -20px;
				margin-right: -20px;

				li {
					display: inline-block;
					vertical-align: top;
					padding: 0 20px;

					a {
						display: block;
						padding-bottom: 4px;
						position: relative;
						opacity: 1;

						&::before {
							content: "";
							width: 0;
							height: 2px;
							background: #d53230;
							position: absolute;
							bottom: 0;
							left: 0;
							@include transition(all 0.3s);
						}
						&:hover,
						&.active {
							&::before {
								width: 100%;
							}
						}
						// &.active {
						// 	cursor: default;
						// }
					}
					&:first-child {
						a {
							letter-spacing: 1.5px;
						}
					}
				}
			}
		}
		&--sp {
			display: none;
		}
	}
	@media (min-width: 768px) and (max-width: 999px) {
		padding-top: 0;
		&__main {
			&--logo {
				top: -3px;
			}
			&--btn {
				.tel {
					width: 36%;
					span {
						font-size: 24px;
					}
					small {
						font-size: 10px;
					}
				}
				a {
					width: 30%;
					font-size: 11px;
				}
			}
		}
		&__menu {
			&--pc {
				ul {
					margin-left: -6px !important;
					margin-right: -6px !important;
					li {
						padding: 0 8px !important;
						a {
							font-size: 10px !important;
						}
					}
				}
			}
		}
	}
	@media (min-width: 768px) and (max-width: 1036px) {
		&__menu {
			&--pc {
				ul {
					margin-left: -22px;
					margin-right: -22px;
					text-align: center;
					li {
						padding: 0 22px;
						a {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		z-index: 999;
		background: #fff;
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		padding: 0 !important;

		&__main {
			height: 50px;
			margin-bottom: 0;

			&--logo {
				width: 129px;
				top: 2px;
				left: 13px;
			}
			&--btn {
				width: auto;

				a {
					height: 50px;
					opacity: 1 !important;
					width: 55px;
					font-size: 8px;
					text-align: center;
					line-height: 1.6;
					padding: 0;

					&.btn_contact {
						background-image: url(../img/common/icon/ico_question_mark.png);
						background-position: top 10px center;
						background-size: 8px 12px;
						padding: 24px 5px 0;
						line-height: 1.4;
						letter-spacing: -0.7px;
						margin: 0;
					}
				}
				.btn_menusp {
					background: #080404;
					padding-top: 12px;

					&__hamburger {
						display: block;
						margin: 0 auto 5px;
						position: relative;
						width: 18px;
						height: 12px;

						span {
							width: 100%;
							display: block;
							height: 2px;
							background: #fff;
							position: absolute;
							transition: -webkit-transform 0.3s;
							transition: transform 0.3s;
							left: 0;
							top: 0;

							&:nth-child(2) {
								top: 5px;
							}
							&:last-child {
								margin-bottom: 0;
								top: 10px;
							}
						}
					}
					small {
						font-weight: bold;
						display: block;
						color: #fff;
						letter-spacing: -0.5px;
					}
				}
			}
		}
		&__menu {
			&--sp {
				z-index: 999;
				position: absolute;
				top: 50px;
				background: #fff;
				left: 0;
				right: 0;
				width: 100%;
				padding: 0;
				border-top: 1px solid #080404;

				.menusp_wrapper {
					overflow: auto;

					&__ul {
						padding: 0 15px;
						margin-bottom: 18px;
					}
				}
				ul {
					margin-left: -10px;
					margin-right: -10px;
					font-size: 0;

					li {
						width: 100%;
						padding: 0 10px;
						display: inline-block;
						vertical-align: top;

						a {
							font-size: 12px;
							display: block;
							background: url(../img/common/icon/ico_ar_right_black_menu.png) no-repeat center right 4px;
							background-size: 11px 6px;
							padding: 13px 0 14px;
							border-bottom: 1px dashed #080404;
							letter-spacing: -0.5px;
						}
						&.half {
							width: 50%;

							a {
								background-position: center right 2px;
							}
						}
					}
				}
				.btn_closemenu {
					background: #080404;
					text-align: center;
					display: block;
					padding: 9px 0 7px;

					span {
						display: inline-block;
						vertical-align: top;
						background: url(../img/common/icon/ico_close_menu.png) no-repeat center left;
						background-size: 13px 13px;
						padding-left: 22px;
						font-size: 16px;
						color: #fff;
						letter-spacing: 0.5px;
					}
				}
			}
		}
		&.opened {
			.header__main {
				&--btn {
					.btn_menusp {
						&__hamburger {
							span {
								margin: 0;
								position: absolute;
							}
							span:first-child {
								-webkit-transform: rotate(45deg) translateX(4px) translateY(5px);
								-moz-transform: rotate(45deg) translateX(4px) translateY(5px);
								-ms-transform: rotate(45deg) translateX(4px) translateY(5px);
								transform: rotate(45deg) translateX(4px) translateY(5px);
							}
							span:nth-child(2) {
								opacity: 0;
							}
							span:last-child {
								-webkit-transform: rotate(-45deg) translateX(2px) translateY(-3px);
								-moz-transform: rotate(-45deg) translateX(2px) translateY(-3px);
								-ms-transform: rotate(-45deg) translateX(2px) translateY(-3px);
								transform: rotate(-45deg) translateX(2px) translateY(-3px);
							}
						}
					}
				}
			}
		}
	}
	@media (min-width: 768px) {
		margin-bottom: 12px;

		&__menu.fixed {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			background: #fff;
			padding: 0 20px;
			box-shadow: 0px 0px 10px 0px #999;
		}
	}
}
