@charset 'UTF-8';

.passive-design {

  &__wrapper {
    overflow: hidden;
  }
  &__block01 {
    padding-top: 59px;
    padding-bottom: 96px;

    > img {
      width: 100%;
      display: block;
      margin-bottom: 89px;
    }
    &--content {
      @include clearfix();

      .txt, img {
        float: left;
      }
      .txt {
        width: 55.5%;
        position: relative;
        top: -1px;
        padding-right: 5%;

        strong {
          display: block;
          font-size: 28px;
          line-height: 1.2;
          margin-bottom: 33px;

          &:first-child {
            margin-bottom: 23px;
          }
        }
        p {
          font-size: 16px;
          text-align: justify;
          line-height: 2.15;
        }
      }
      img {
        width: 44.5%;
      }
    }
  }
  &__block02 {
    background: #f1f1f1;
    font-size: 0;
    text-align: center;
    padding: 76px 0 115px;
    margin-bottom: 90px;

    ul {
      margin-left: -26px;
      margin-right: -26px;
      margin-bottom: 61px;

      li {
        width: 100%;
        width: 50%;
        display: inline-block;
        vertical-align: top;
        padding: 0 26px;

        .txt {
          background: #fff;
          padding: 37px 25px 45px;
        }
        strong {
          display: block;
          font-size: 22px;
          margin-bottom: 35px;
          letter-spacing: 0.5px;
        }
        img {
          width: 100%;
        }
      }
    }
    &--bot {
      display: block;
      font-size: 26px;
      line-height: 1.7;
    }
  }
  &__block03 {
    max-width: 843px;
    padding: 20px 20px;
    margin: 0 auto 130px;

    ul {
      margin-bottom: 88px;

      li {
        border-bottom: 1px dashed #080404;
        padding-bottom: 62px;
        margin-bottom: 68px;

        .num, strong {
          display: inline-block;
          vertical-align: middle;
        }
        .num {
          line-height: 1;
          font-size: 38px;
          font-weight: 500;
          position: relative;
          margin: 0 15px 0 5px;
          padding-right: 22px;

          &::before {
            content: "";
            background: #080404;
            width: 3px;
            height: 31px;
            position: absolute;
            top: 5px;
            right: 0;
          }
        }
        strong {
          font-size: 24px;
          letter-spacing: 1px;
        }
        p {
          font-size: 16px;
          padding-left: 1px;
          margin: 35px 0 42px;
        }
        ul {
          font-size: 0;
          margin-left: -13px;
          margin-right: -13px;
          margin-bottom: 0;

          li {
            border: 0;
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding: 0 13px;
            margin: 0;

            img {
              width: 100%;
              margin-bottom: 16px;
            }
            span {
              display: block;
              font-size: 14px;
            }
          }
        }
      }
    }
    &--bot, &--btn {
      text-align: center;
      display: block;
    }
    &--bot {
      font-size: 26px;
      line-height: 1.7;
      margin-bottom: 95px;
    }
    &--btn {

      em, a {
        display: inline-block;
        vertical-align: middle;
      }
      em {
        letter-spacing: 1px;
        margin-right: 34px;
      }
      a {
        padding: 13px 56px 14px;
      }
    }
  }
  @media (max-width:767px) {

    &__block01 {
      padding: 0 0 61px;

      > img {
        margin-bottom: 42px;
      }
      &--content {
        padding: 0 15px;

        .txt, img {
          width: 100%;
        }
        .txt {

          strong {
            font-size: 17px;
            text-align: center;
            line-height: 1.55;
            letter-spacing: 1px;
            margin-bottom: 22px;

            &:first-child {
              margin-bottom: 12px;
            }
          }
          p {
            font-size: 13px;
            line-height: 1.65;
            padding: 0 1px;
            margin-bottom: 26px;
          }
        }
      }
    }
    &__block02 {
      padding: 66px 0 59px;
      margin-bottom: 56px;

      .wcm {
        padding: 0 15px;
      }
      ul {
        margin: 0 0 14px;

        li {
          width: 100%;
          padding: 0;
          margin-bottom: 18px;

          .txt {
            padding: 24px 15px 27px;

            strong {
              font-size: 15px;
              margin-bottom: 16px;
            }
          }
        }
      }
      &--bot {
        font-size: 17px;
        line-height: 1.55;
        letter-spacing: 1px;
      }
    }
    &__block03 {
      padding: 0 15px;
      margin-bottom: 82px;

      ul {
        margin: 0 -15px 43px;

        li {
          padding: 0 15px 11px;
          margin-bottom: 40px;

          .num {
            font-size: 21px;
            margin: 0 15px 0 0;
            letter-spacing: 1px;
            padding-right: 14px;

            &::before {
              height: 21px;
              top: 0;
            }
          }
          strong {
            font-size: 15px;
            letter-spacing: 0.5px;
          }
          p {
            font-size: 13px;
            margin: 15px 0 25px;
            line-height: 1.65;
          }
          ul {
            margin: 0;

            li {
              padding: 0;
              width: 100%;
              margin-bottom: 34px;

              img {
                margin-bottom: 12px;
              }
              span {
                font-size: 12px;
                line-height: 1.3;
              }
            }
          }
        }
      }
      &--bot {
        font-size: 17px;
        line-height: 1.55;
        margin-bottom: 66px;

        strong {
          display: block;
          margin-bottom: 12px;
        }
      }
      &--btn {

        em {
          font-size: 18px;
          letter-spacing: 2px;
          font-weight: bold;
          margin: 0 0 21px;
          display: block;
        }
        a {
          padding: 7px 60px 5px;
          line-height: 1.4;
        }
      }
    }
  }
}
