@charset 'UTF-8';

.event {
  &__wrapper {
    @include clearfix();
  }
  &__archive {
    padding-top: 60px;
    margin-bottom: 97px;

    &--left,
    &--right {
      float: left;
    }
    &--left {
      width: 80%;
      padding-right: 101px;
    }
    &--right {
      width: 20%;
    }
    &--item {
      @include clearfix();
      padding-bottom: 46px;
      border-bottom: 1px dashed #080404;
      margin-bottom: 45px;

      img {
        width: 227px;
        float: left;
        margin-right: 29px;
      }
      .txt {
        overflow: hidden;
        padding-top: 1px;

        span {
          display: inline-block;
          vertical-align: middle;
        }
        .date {
          border: 1px solid #080404;
          font-size: 12px;
          padding: 1px 10px 1px 12px;
          letter-spacing: 1.5px;
          margin-right: 13px;
        }
        .begin_date,
        .end_date {
          letter-spacing: 1.8px;
        }
        .begin_date {
          margin-right: 12px;
        }
        .title {
          display: block;
          font-size: 18px;
          font-weight: bold;
          text-decoration: underline;
          margin: 15px 0 9px;
          line-height: 1.75;
          padding-left: 3px;
          letter-spacing: 0.5px;
          @include transition(all 0.3s);

          &:hover {
            opacity: 0.5;
          }
        }
        a {
          display: inline-block;
          position: relative;
          margin-left: 3px;
          text-decoration: underline;
          padding-right: 18px;

          &::before {
            content: "";
            background: url(../img/column/ico_ar_right_gray.svg) no-repeat center center;
            background-size: 100% 100%;
            width: 12px;
            height: 6px;
            position: absolute;
            top: 8px;
            right: 0;
          }
        }
      }
    }
    &--listcat {
      background: #f1f1f1;
      padding: 24px 22px;

      strong {
        display: block;
        font-size: 16px;
        margin-bottom: 8px;
      }
      ul {
        li {
          margin-bottom: 6px;

          a {
            text-decoration: underline;
            padding-left: 20px;
            background: url(../img/column/ico_ar_right_black.png) no-repeat center left;
            letter-spacing: 1px;
          }
        }
      }
    }
    .wp-pagenavi {
      margin-top: 78px;
    }
  }
  &__detail {
    margin-bottom: 0;

    &.c_confirm {
      padding-top: 0;
      padding-left: 20px;
      padding-right: 20px;
    }
    .event__wrapper--inner {
      @include clearfix();
      margin-bottom: 164px;
    }
    .event__archive--left {
      padding-right: 50px;
    }
    .detail_info {
      @include clearfix();
      margin-bottom: 16px;
    }
    &--date,
    &--begin,
    &--end {
      display: inline-block;
      vertical-align: middle;
    }
    &--date {
      font-size: 12px;
      border: 1px solid #080404;
      padding: 3px 10px 3px 12px;
      letter-spacing: 1.5px;
      margin-right: 13px;
    }
    &--begin,
    &--end {
      letter-spacing: 1.8px;
    }
    &--begin {
      margin-right: 12px;
    }
    &--title {
      font-size: 26px;
      display: block;
      letter-spacing: 2.2px;
      margin-bottom: 39px;
    }
    &--content {
      font-size: 16px;
      margin-bottom: 54px;

      img {
        margin-bottom: 32px;
      }
      p {
        line-height: 1.65;
        letter-spacing: -0.5px;
        margin-bottom: 25px;
      }
      h1,
      h2 {
        font-weight: bold;
        display: block;
      }
      h1 {
        font-size: 22px;
        border-left: 4px solid #080404;
        padding: 1px 0 1px 18px;
        line-height: 1.75;
        letter-spacing: 0.3px;
        margin-bottom: 25px;
      }
      h2 {
        font-size: 20px;
        letter-spacing: 0.4px;
        margin-bottom: 15px;
      }
      ul {
        padding-left: 5px;
        margin-bottom: 34px;

        li {
          padding-left: 20px;
          position: relative;
          margin-bottom: 7px;

          &::before {
            content: "";
            width: 7px;
            height: 7px;
            background: #080404;
            @include border-radius(50%);
            position: absolute;
            left: 0;
            top: 9px;
          }
        }
      }
      a {
        color: #d53230;
        text-decoration: underline;
      }
    }
    &--backlist {
      text-align: center;

      .top__cmbtn {
        padding: 13px 71px 14px;
      }
    }
    &--formhd {
      background: #f1f1f1;
      padding: 68px 20px 66px;
      text-align: center;
      font-size: 16px;
      margin-bottom: 78px;

      strong,
      span {
        display: block;
      }
      strong {
        font-size: 28px;
        margin-bottom: 12px;
      }
      span {
        color: #333;
        letter-spacing: 1.3px;
      }
    }
    &--formwrapper {
      padding: 0 20px;
    }
    .contactpage {
      .step-2 {
        margin-top: 85px;
      }
      .tableContact {
        th {
          padding-right: 10px;
          letter-spacing: 0;
        }
        .bookdate {
          font-size: 0;

          &__row:first-child {
            margin-bottom: 25px;
          }
          span,
          input {
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
          }
          input {
            width: 72px;
            margin: 0 7px 0 15px;

            &:last-child {
              margin-right: 0;
            }
          }
          .ipyear {
            width: 169px;
            margin: 0 6px 0 18px;
          }
        }
        .bookperson {
          font-size: 0;

          &__row {
            display: inline-block;
            vertical-align: middle;

            &:first-child {
              margin-right: 49px;
            }
            input,
            span {
              font-size: 16px;
              display: inline-block;
              vertical-align: middle;
            }
            input {
              width: 101px;
              margin: 0 8px 0 12px;
            }
          }
        }
        .date_confirm {
          &__row {
            &:first-child {
              margin-bottom: 12px;
            }
            span:nth-of-type(1) {
              margin: 0 5px 0 26px;
            }
            span:nth-of-type(2) {
              margin: 0 10px;
            }
          }
        }
        .person_confirm {
          span {
            display: inline-block;

            &:first-child {
              margin-right: 100px;
            }
            em {
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 999px) {
    &__archive {
      &--left {
        width: 72%;
        padding-right: 30px;
      }
      &--right {
        width: 28%;
      }
      &--item {
        img {
          width: 170px;
          margin-right: 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
    &__archive {
      padding: 18px 0 0;
      margin-bottom: 56px;

      &--left,
      &--right {
        padding: 0;
        width: 100%;
        float: none;
      }
      &--left {
        margin-bottom: 22px;
      }
      &--item {
        margin-bottom: 58px;
        border: 1px solid #080404;
        padding: 0;

        img {
          width: 100%;
          float: none;
          margin: 0;
          display: block;
        }
        .txt {
          padding: 22px 15px 25px;
          max-height: 100%;
          height: auto;
          position: static;

          .info {
            @include clearfix();
          }
          span {
            font-size: 12px;
          }
          .date {
            border: 0;
            background: #f1f1f1;
            padding: 4px 10px 6px 14px;
            float: left;
            margin-right: 14px;
          }
          .begin_date,
          .end_date {
            display: block;
            overflow: hidden;
            position: relative;
            top: -4px;
            letter-spacing: 1.5px;
          }
          .begin_date {
            margin: 0 0 -3px;
          }
          .title {
            font-size: 15px;
            padding-left: 5px;
            margin: 7px 0 0;
            line-height: 1.6;
          }
          a {
            display: none;
          }
        }
      }
      &--listcat {
        background: none;
        padding: 0;
        position: relative;

        .btn_showarchive {
          border: 1px solid #080404;
          display: block;
          margin: auto;
          width: 230px;
          height: 42px;
          @include border-radius(3px);
          position: relative;
          overflow: hidden;
          font-size: 13px;
          line-height: 42px;
          letter-spacing: -0.2px;
          @include clearfix();

          span,
          em {
            float: left;
          }
          span {
            position: relative;
            background: #080404;
            width: 37px;
            height: 100%;
            &::before,
            &::after {
              content: "";
              background: #fff;
              width: 13px;
              height: 1px;
              position: absolute;
              @include transition(all 0.3s);
              left: 0;
              right: 0;
              margin: auto;
              top: 20px;
            }
            &::before {
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              transform: rotate(90deg);
            }
            &::after {
            }
          }
          em {
            width: 191px;
            display: block;
            padding-left: 24px;

            &.tc {
              text-align: center;
              padding-left: 0;
            }
          }
          &.active {
            span {
              &::before {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &::after {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }
        ul {
          display: none;
          position: absolute;
          width: 100%;
          max-width: 290px;
          left: 0;
          right: 0;
          margin: auto;
          top: 42px;
          border: 1px solid #080404;
          padding: 8px 17px 24px;
          background: #fff;

          li {
            margin: 0;

            a {
              font-size: 13px;
              text-decoration: none;
              display: block;
              background-position: center right;
              background-size: 11px 6px;
              padding: 13px 0;
              border-bottom: 1px dashed #808080;
              letter-spacing: 0;
            }
          }
        }
      }
      .wp-pagenavi {
        margin-top: 15px;
      }
    }
    &__detail {
      padding-top: 16px;
      margin-bottom: 0;

      &.c_confirm {
        padding: 0;
      }
      .event__archive--right {
        display: none;
      }
      .event__wrapper--inner {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 44px;
      }
      .event__archive--left {
        padding-right: 0;
        margin-bottom: 30px;
      }
      .detail_info {
        margin-bottom: 8px;
      }
      &--date {
        float: left;
        margin-right: 10px;
        padding: 4px 11px 5px;
        margin-right: 15px;
      }
      &--begin,
      &--end {
        display: block;
        overflow: hidden;
        font-size: 12px;
        position: relative;
        top: -2px;
        letter-spacing: 2.5px;
      }
      &--begin {
        margin: 0 0 -3px;
      }
      &--title {
        font-size: 17px;
        margin-bottom: 27px;
      }
      &--content {
        font-size: 13px;
        margin-bottom: 40px;

        img {
          margin-bottom: 16px;
        }
        p {
          margin-bottom: 18px;
        }
        h1 {
          font-size: 15px;
          border-width: 3px;
          padding: 2px 0px 2px 18px;
          line-height: 1.35;
          letter-spacing: 0.6px;
          margin-bottom: 10px;
        }
        h2 {
          font-size: 14px;
          margin-bottom: 8px;
        }
        ul {
          margin-bottom: 26px;

          li {
            padding-left: 10px;
            margin-bottom: 8px;

            &::before {
              width: 6px;
              height: 6px;
              top: 6px;
            }
          }
        }
      }
      &--backlist {
        .top__cmbtn {
          padding: 15px 60px 14px;
        }
      }
      &--formhd {
        margin-left: -15px;
        margin-right: -15px;
        padding: 26px 15px 29px;
        margin-bottom: 33px;

        strong {
          font-size: 17px;
          margin-bottom: 5px;
        }
        span {
          font-size: 13px;
          color: #080404;
          letter-spacing: 0.2px;
        }
      }
      &--formwrapper {
        padding: 0;
      }
      &--namecell {
        th {
          background: #f1f1f1 !important;
          padding-top: 12px !important;
          padding-bottom: 10px !important;
        }
        td {
          padding-top: 13px !important;
          padding-bottom: 13px !important;
          font-size: 14px !important;
        }
      }
      .contactpage {
        .tableContact {
          border-top: 0;

          .bookdate {
            &__row {
              margin: 0 -5px 0 0;

              &:first-child {
                margin-bottom: 15px;
              }
            }
            input {
              width: 60px;
              margin: 0 5px 0 11px;
            }
            span {
              font-size: 14px;

              &:first-child {
                display: block;
                margin-bottom: 4px;
                padding-left: 9px;
              }
            }
            .ipyear {
              font-size: 13px;
              width: 214px;
              margin: 0 5px 0 0;
            }
          }
          .bookperson {
            &__row {
              width: 100%;

              &:first-child {
                margin-right: 0;
                margin-bottom: 12px;
              }
            }
            input {
              width: 86px;
              margin: 0 6px 0 20px;
            }
            span {
              font-size: 14px;
            }
          }
          .date_confirm {
            font-size: 14px;
            padding-left: 23px;

            &__row {
              &:first-child {
                margin-bottom: 0;
              }
            }
          }
          .person_confirm {
            padding-left: 23px;

            span {
              &:first-child {
                margin-right: 25px;
              }
            }
          }
        }
        .formBlock {
          .chkform {
            margin-bottom: 38px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    &__archive {
      &--listcat {
        ul {
          display: block !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .event_step_confirm {
    padding-top: 0;
  }
  .event_step_confirm .contactpage .step-2 {
    margin-top: 32px;
  }
}
