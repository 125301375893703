@charset 'UTF-8';

.column {

  &__wrapper {
    @include clearfix();
  }
  &__archive {
    padding-top: 60px;
    margin-bottom: 128px;

    &--left, &--right {
      float: left;
    }
    &--left {
      width: 80%;
      padding-right: 101px;
    }
    &--right {
      width: 20%;
    }
    &--item {
      @include clearfix();
      border-bottom: 1px dashed #080404;
      padding-bottom: 26px;
      margin-bottom: 24px;

      img {
        width: 100px;
        float: left;
        margin-right: 29px;
      }
      .txt {
        overflow: hidden;
        height: 100px;
        position: relative;

        span {
          position: relative;
          top: -3px;
          color: #808080;
          display: block;
          letter-spacing: 2.8px;
          margin-bottom: -1px;
        }
        .title {
          font-size: 18px;
          text-decoration: underline;
          max-height: 56px;
          overflow: hidden;
          @include transition(all .3s);

          &:hover {
            opacity: 0.5;
          }
        }
        a {
          display: inline-block;
          padding-right: 18px;
          color: #808080;
          text-decoration: underline;
          position: absolute;
          bottom: -2px;
          left: 4px;

          &::before {
            content: "";
            background: url(../img/column/ico_ar_right_gray.svg) no-repeat center center;
            background-size: 100% 100%;
            width: 12px;
            height: 6px;
            position: absolute;
            top: 8px;
            right: 0;
          }
        }
      }
    }
    &--listcat {
      background: #f1f1f1;
      padding: 24px 22px;

      strong {
        display: block;
        font-size: 16px;
        margin-bottom: 8px;
      }
      ul {

        li {
          margin-bottom: 6px;

          a {
            text-decoration: underline;
            padding-left: 20px;
            background: url(../img/column/ico_ar_right_black.png) no-repeat center left;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
  &__detail {
    margin-bottom: 167px;

    .column__wrapper--inner {
      @include clearfix();
      border-bottom: 1px dashed #080404;
      padding-bottom: 136px;
      margin-bottom: 132px;
    }
    .column__archive--left {
      padding-right: 50px;
    }
    &--date {
      position: relative;
      top: -3px;
      display: block;
      letter-spacing: 3px;
      margin-bottom: 4px;
    }
    &--title {
      font-size: 26px;
      display: block;
      letter-spacing: 2.2px;
      margin-bottom: 39px;
    }
    &--content {
      font-size: 16px;
      margin-bottom: 66px;

      img {
        margin-bottom: 32px;
      }
      p {
        line-height: 1.65;
        letter-spacing: -0.5px;
        margin-bottom: 25px;
      }
      h1, h2 {
        font-weight: bold;
        display: block;
      }
      h1 {
        font-size: 22px;
        border-left: 4px solid #080404;
        padding: 1px 0 1px 18px;
        line-height: 1.75;
        letter-spacing: 0.3px;
        margin-bottom: 25px;
      }
      h2 {
        font-size: 20px;
        letter-spacing: 0.4px;
        margin-bottom: 15px;
      }
      ul {
        padding-left: 5px;
        margin-bottom: 34px;

        li {
          padding-left: 20px;
          position: relative;
          margin-bottom: 7px;

          &::before {
            content: "";
            width: 7px;
            height: 7px;
            background: #080404;
            @include border-radius(50%);
            position: absolute;
            left: 0;
            top: 9px;
          }
        }
      }
      a {
        color: #d53230;
        text-decoration: underline;
      }
    }
    &--sns {

      span,ul {
        display: inline-block;
        vertical-align: middle;
      }
      span {
        font-weight: bold;
        position: relative;
        background: #f1f1f1;
        padding: 10px 8px 11px 17px;
        letter-spacing: 0.5px;
        margin-right: 40px;

        &::before {
          content: "";
          border-top: 22px solid transparent;
          border-bottom: 22px solid transparent;
          border-left: 16px solid #f1f1f1;
          position: absolute;
          right: -16px;
          top: 0;
        }
      }
      ul {
        font-size: 0;

        li {
          display: inline-block;
          vertical-align: middle;
          margin-right: 22px;

          a {
            display: block;
          }
          &:last-child {
            margin-right: 0;
          }
          img {
            display: block;
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    &--part {
      text-align: center;

      strong {
        display: inline-block;
        font-size: 20px;
        padding-left: 20px;
        background-repeat: no-repeat;
        background-position: center left;
        margin-bottom: 40px;
      }
      ul {
        font-size: 0;
        margin-left: -6px;
        margin-right: -6px;
        text-align: left;

        li {
          width: 25%;
          display: inline-block;
          vertical-align: top;
          padding: 0 6px;

          .txt {
            padding: 0 10px;
          }
          img {
            width: 100%;
            margin-bottom: 12px;
          }
          span {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            letter-spacing: 1.7px;
          }
          a {
            text-decoration: underline;
            font-size: 16px;
            display: block;
            margin-top: 4px;
          }
        }
      }
    }
    &--related {
      margin-bottom: 109px;

      strong {
        background-image: url(../img/column/ico_news.svg);
        background-size: 30px 24px;
        padding-left: 46px;
      }
    }
    &--latest {
      strong {
        background-image: url(../img/column/ico_latest.svg);
        background-size: 40px 19px;
        padding-left: 52px;
      }
    }
  }
  @media (min-width:768px) and (max-width:999px) {

    &__archive {

      &--left {
        width: 72%;
        padding-right: 30px;
      }
      &--right {
        width: 28%;
      }
    }
  }
  @media (max-width:767px) {

    &__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
    &__archive {
      padding: 18px 0 0;
      margin-bottom: 54px;

      &--left, &--right {
        padding: 0;
        width: 100%;
        float: none;
      }
      &--left {
        margin-bottom: 22px;
      }
      &--item {
        height: auto;
        padding-bottom: 19px;
        margin-bottom: 23px;

        img {
          margin-right: 19px;
        }
        .txt {
          max-height: 100%;
          height: auto;

          span {
            font-size: 11px;
            top: -2px;
            letter-spacing: 2.2px;
            margin-bottom: 4px;
          }
          .title {
            padding-right: 5px;
            font-size: 14px;
            max-height: 44px;
            margin-bottom: 16px;
          }
          a {
            position: relative;
            vertical-align: top;
            bottom: 0;
            left: 0;
            font-size: 0;
            width: 11px;
            height: 6px;
            padding: 0;

            &::before {
              top: 0;
              right: 0;
              width: 11px;
              height: 6px;
            }
          }
        }
      }
      &--listcat {
        background: none;
        padding: 0;
        position: relative;

        .btn_showarchive {
          border: 1px solid #080404;
          display: block;
          margin: auto;
          width: 230px;
          height: 42px;
          @include border-radius(3px);
          position: relative;
          overflow: hidden;
          font-size: 13px;
          line-height: 42px;
          letter-spacing: -0.2px;
          @include clearfix();

          span,em {
            float: left;
          }
          span {
            position: relative;
            background: #080404;
            width: 37px;
            height: 100%;
            &::before, &::after {
              content: "";
              background: #fff;
              width: 13px;
              height: 1px;
              position: absolute;
              @include transition(all .3s);
              left: 0;
              right: 0;
              margin: auto;
              top: 20px;
            }
            &::before {
              -webkit-transform: rotate(90deg);
  				    -moz-transform: rotate(90deg);
  				    -ms-transform: rotate(90deg);
  				    transform: rotate(90deg);
            }
            &::after {

            }
          }
          em {
            width: 191px;
            display: block;
            padding-left: 24px;

            &.tc {
              text-align: center;
              padding-left: 0;
            }
          }
          &.active {
            span {
              &::before {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
              &::after {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
              }
            }
          }
        }
        ul {
          display: none;
          position: absolute;
          width: 100%;
          max-width: 290px;
          left: 0;
          right: 0;
          margin: auto;
          top: 42px;
          border: 1px solid #080404;
          padding: 8px 17px 24px;
          background: #fff;

          li {
            margin: 0;

            a {
              font-size: 13px;
              text-decoration: none;
              display: block;
              background-position: center right;
              background-size: 11px 6px;
              padding: 13px 0;
              border-bottom: 1px dashed #808080;
              letter-spacing: 0;
            }
          }
        }
      }
    }
    &__detail {
      padding-top: 16px;
      margin-bottom: 40px;

      .column__wrapper--inner {
        padding-bottom: 36px;
        margin-bottom: 60px;
      }
      .column__archive--left {
        padding-right: 0;
        margin-bottom: 30px;
      }
      &--date {
        position: static;
        font-size: 11px;
        letter-spacing: 2.3px;
      }
      &--title {
        font-size: 17px;
        margin-bottom: 21px;
      }
      &--content {
        font-size: 13px;
        margin-bottom: 45px;

        img {
          margin-bottom: 16px;
        }
        p {
          margin-bottom: 18px;
        }
        h1 {
          font-size: 15px;
          border-width: 3px;
          padding: 2px 0px 2px 18px;
          line-height: 1.35;
          letter-spacing: 0.6px;
          margin-bottom: 10px;
        }
        h2 {
          font-size: 14px;
          margin-bottom: 8px;
        }
        ul {
          margin-bottom: 26px;

          li {
            padding-left: 10px;
            margin-bottom: 8px;

            &::before {
              width: 6px;
              height: 6px;
              top: 6px;
            }
          }
        }
      }
      &--sns {
        margin-left: -15px;
        margin-right: -15px;
        background: #f1f1f1;
        padding: 21px 15px 22px;

        span {
          background: #fff;
          font-size: 12px;
          margin-right: 26px;
          padding: 9px 0 9px 13px;
          vertical-align: top;
          top: 2px;

          &::before {
            border-left-color: #fff;
            border-top-width: 19px;
            border-bottom-width: 19px;
            border-left-width: 12px;
            right: -12px;
          }
        }
        ul {

          li {
            margin-right: 12px;

            img {
              width: 41px;
              height: 41px;
            }
          }
        }
      }
      &--part {

        strong {
          font-size: 14px;
          margin-bottom: 31px;
        }
        ul {
          margin-left: -4px;
          margin-right: -4px;

          li {
            padding: 0 4px;
            width: 50%;
            margin-bottom: 42px;

            .txt {
              padding: 0 5px;
            }
            img {
              margin-bottom: 8px;
            }
            span {
              font-size: 12px;
              letter-spacing: 2.5px;
            }
            a {
              font-size: 13px;
              margin-top: 3px;
              line-height: 1.5;
            }
          }
        }
      }
      &--related {
        margin-bottom: 51px;

        strong {
          background-size: 22px 18px;
          padding-left: 33px;
        }
      }
      &--latest {

        strong {
          background-size: 26px 12px;
          padding-left: 36px;
        }
      }
    }
  }
  @media (min-width:768px) {
    &__archive {
      &--listcat {
        ul {
          display: block !important;
        }
      }
    }
  }
}
