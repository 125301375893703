@charset 'UTF-8';

.works {
  &__archive {
    padding: 61px 0 0;
    margin-bottom: 132px;
    &--search {
      max-width: 840px;
      padding: 0 20px;
      margin: 0 auto 59px;
      position: relative;
      .btn_showsearch {
        border: 1px solid #080404;
        width: 261px;
        height: 51px;
        margin: auto;
        display: block;
        cursor: pointer;
        @include clearfix();

        span,
        em {
          float: left;
          height: 100%;
        }
        span {
          background: #080404;
          width: 42px;
          position: relative;

          &::before,
          &::after {
            content: "";
            background: #fff;
            width: 16px;
            height: 1px;
            position: absolute;
            @include transition(all 0.3s);
            left: 0;
            right: 0;
            margin: auto;
            top: 23px;
          }
          &::before {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
        em {
          width: 217px;
          padding-top: 13px;
          padding-left: 26px;
          letter-spacing: 0.7px;

          &.tc {
            text-align: center;
            padding-left: 0;
          }
        }
        &.active {
          span {
            &::before {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &::after {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }
        }
      }
      .searchframe {
        max-width: 800px;
        background: #fff;
        display: none;
        width: 100%;
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        margin: auto;
        border: 1px solid #080404;
        padding: 33px 34px 38px;
        z-index: 2;

        &__part {
          font-size: 16px;
          border-bottom: 1px dashed #808080;
          padding-bottom: 20px;
          margin-bottom: 26px;

          strong {
            display: block;
            margin-bottom: 7px;
          }
          ul {
            font-size: 0;

            li {
              margin-bottom: 6px;
              width: 50%;
              display: inline-block;
              vertical-align: top;
              font-size: 16px;

              input {
                margin-right: 14px;
                outline: none;
              }
            }
          }
        }
        .ct {
          width: 100%;
          display: inline-block;
          text-align: center;
          margin-top: 11px;
        }
      }
    }
    &--list-wrapper {
      overflow: hidden;
    }
    &--list {
      font-size: 0;
      margin-left: -3px;
      margin-right: -3px;

      .item {
        @include transition(all 0.3s);
        width: 25%;
        display: inline-block;
        vertical-align: top;
        padding: 0 3px;
        margin-bottom: 61px;

        img {
          width: 100%;
        }
        &__title {
          text-decoration: underline;
          font-weight: bold;
          font-size: 18px;
          padding-left: 2px;
          letter-spacing: 0.3px;
          margin-bottom: 7px;
          a {
            opacity: 1;
          }
        }
        &__content {
          padding: 19px 15px 16px;

          span {
            display: block;
            font-size: 14px;
            letter-spacing: 0.5px;
          }
        }
        &__cat {
          p {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            border: 1px solid #080404;
            padding: 2px 7px 4px;
            margin: 9px 9px 9px 0;
            letter-spacing: 0.5px;
            @include SP {
              margin-bottom: 0;
            }
          }
        }
        &:hover {
          opacity: 0.5;

          .item__content {
            background: #e9e9e9;
          }
        }
      }
    }
    .wp-pagenavi {
      margin-top: 107px;
    }
    &--notfound {
      font-size: 16px;
      text-align: center;
      padding: 0 20px;
    }
    @media (max-width: 767px) {
      padding: 17px 0 0;
      margin-bottom: 58px;

      &--search {
        padding: 0 15px;
        margin-bottom: 41px;

        .btn_showsearch {
          @include border-radius(3px);
          width: 231px;
          height: 42px;

          span {
            width: 36px;

            &::before,
            &::after {
              width: 13px;
              top: 20px;
            }
          }
          em {
            width: 193px;
            font-size: 13px;
            padding-top: 12px;
            padding-left: 25px;
            letter-spacing: 0;
          }
        }
        .searchframe {
          top: 41px;
          margin: 0 15px;
          width: auto;
          padding: 16px 15px 28px;

          &__part {
            padding-bottom: 10px;
            margin-bottom: 15px;

            strong {
              font-size: 14px;
            }
            ul {
              li {
                width: 100%;
                font-size: 13px;
                margin-bottom: 8px;

                input {
                  margin-right: 12px;
                  position: relative;
                  top: 1px;
                }
              }
            }
          }
          .ct {
            margin-top: 7px;
          }
        }
      }
      &--list {
        margin-left: -2px;
        margin-right: -2px;

        .item {
          width: 50%;
          padding: 0 2px;
          height: auto !important;
          margin-bottom: 33px;

          &__title {
            line-height: 1.5;
            font-size: 15px;
            padding: 0;
            margin-bottom: 5px;
          }
          &__content {
            padding: 10px 11px;
            height: auto !important;

            span {
              font-size: 12px;
            }
          }
          &:hover {
            .item__content {
              background: none;
            }
          }
        }
      }
      .wp-pagenavi {
        margin-top: 35px;
      }
      &--notfound {
        font-size: 13px;
        padding: 0 15px;
      }
    }
  }
  &__detail {
    padding: 58px 0 0;
    margin-bottom: 111px;
    &--wrapper {
      max-width: 838px;
      padding: 0 20px;
      margin-left: auto;
      margin-right: auto;
    }
    &--mainimg {
      position: relative;
      padding: 0 20px;
      &::before {
        content: "";
        width: 100%;
        height: 151px;
        background: #f1f1f1;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      img {
        position: relative;
        z-index: 2;
        display: block;
        margin: auto;
      }
    }
    &--info {
      background: #f1f1f1;
      padding: 54px 0 75px;
      margin-bottom: 111px;

      strong,
      span {
        display: block;
      }
      strong {
        font-size: 32px;
        letter-spacing: 1.5px;
        margin-bottom: 26px;
      }
      ul {
        font-size: 0;
        margin-bottom: 3px;
        li {
          display: inline-block;
          vertical-align: top;
          font-size: 12px;
          border: 1px solid #080404;
          padding: 2px 7px 4px;
          margin: 0 9px 9px 0;
          letter-spacing: 0.5px;
        }
      }
      span {
        letter-spacing: 0.5px;
      }
    }
    &--content {
      margin-bottom: 124px;
      font-size: 16px;
      p {
        line-height: 1.65;
        margin-bottom: 43px;
        letter-spacing: -0.6px;
      }
    }
    &--related {
      padding: 92px 0 0;
      border-top: 1px solid #808080;
      strong {
        display: block;
        font-size: 20px;
        background: url(../img/works/ico_house.svg) no-repeat center left;
        background-size: 29px 26px;
        padding-left: 41px;
        letter-spacing: 0.8px;
        margin-bottom: 51px;
      }
      ul {
        font-size: 0;
        margin: 0 -2px 83px;
        li {
          display: inline-block;
          vertical-align: top;
          width: 25%;
          padding: 0 2px;
          @include transition(all 0.3s);
          a,
          span {
            display: block;
          }
          a {
            font-size: 16px;
            font-weight: bold;
            text-decoration: underline;
            margin-bottom: 7px;
            padding-left: 2px;
          }
          span {
            font-size: 12px;
          }
          .txt {
            letter-spacing: 0.5px;
            padding: 12px 10px;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
      .ct {
        text-align: center;
      }
    }
    @media (max-width: 767px) {
      padding: 17px 0 0;
      margin-bottom: 71px;
      &--wrapper,
      &--mainimg {
        padding: 0 15px;
      }
      &--mainimg {
        &::before {
          height: 94px;
        }
      }
      &--info {
        padding: 21px 0 32px;
        margin-bottom: 39px;

        strong {
          font-size: 17px;
          line-height: 1.45;
          letter-spacing: 1px;
          margin-bottom: 16px;
        }
        ul {
          margin-bottom: -1px;
          li {
            font-size: 11px;
            letter-spacing: 0.2px;
            padding: 2px 5px 4px;
            margin: 0 6px 6px 0;
          }
        }
        span {
          font-size: 11px;
        }
      }
      &--content {
        margin-bottom: 32px;
        font-size: 13px;

        p {
          margin-bottom: 20px;
        }
      }
      &--related {
        overflow: hidden;
        padding: 50px 0 0;

        .wcm {
          padding: 0;
        }
        strong {
          font-size: 14px;
          background-size: 19px 17px;
          background-position: top center;
          padding: 27px 0 0;
          text-align: center;
          margin-bottom: 33px;
        }
        ul {
          margin-bottom: 9px;
          li {
            width: 50%;
            margin-bottom: 30px;
            a {
              font-size: 15px;
              line-height: 1.5;
              margin-bottom: 4px;
              max-height: 44px;
              overflow: hidden;
            }
            span {
              font-size: 12px;
            }
            .txt {
              padding: 11px 10px;
            }
          }
        }
        .top__cmnbtn {
          padding: 15px 60px 14px;
        }
      }
    }
  }
}
