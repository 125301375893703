@charset "utf-8";

.errMsg {
	margin-left: 1em;
	padding-bottom: 1px;
	display: block;
	line-height: 1.4;
	text-indent: -.9em;
}

.formError {
	padding-bottom: 13px;
	display: block;
}
.fixed {
	padding-bottom: 0px;
}

	.formError .formErrorClose {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		border: solid #252525 2px;
		border-radius: 9px 9px 9px 9px;
		-moz-border-radius: 9px 9px 9px 9px;
		-webkit-border-radius: 9px 9px 9px 9px;
		display: block;
		width: 16px;
		height: 16px;
		position: absolute;
		top: -4px;
		right: -4px;
		color: #efefef;
		background: #333;
		font-weight: bold;
		text-align: center;
		line-height: 16px !important;
		cursor: pointer;
		box-shadow: 1px -1px 3px #888;
		-moz-box-shadow: 1px -1px 3px #888;
		-webkit-box-shadow: 1px -1px 3px #888;
    _z-index:2;
	}
	.formError .formErrorClose:hover {
		background: #666;
	}
	.fixed .formErrorClose {
		display: none;
	}

	.formError .formErrorContent {
		border-radius: 3px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
		padding: 6px 10px;
		width: 180px;
    position:relative;
		color: #fff;
		background: #252525;
		font-family: "Lucida Sans Unicode","Lucida Grande",Arial,Helvetica,"ヒラギノ丸ゴ Pro W4",HiraMaruPro-W4,"ヒラギノ角ゴ Pro W3","メイリオ",Meiryo,Osaka,sans-serif;
		font-size: 11px;
		box-shadow: 0px 0px 6px #888;
		-moz-box-shadow: 0px 0px 6px #888;
		-webkit-box-shadow: 0px 0px 6px #888;
		margin: 0 0 30px 0;
    _z-index:1;
	}
	.fixed .formErrorContent {
		margin-top: .5em;
		border-radius: 0;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		background: #f60;
		box-shadow: none;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		margin: 0 0 30px 0;
	}
	.fadeOut {
		opacity: .2;
		filter: alpha(opacity=20);
	}

	.formError .formErrorArrow {
		width: 15px;
		height: 15px;
		position: absolute;
		bottom: 0;
		left: 20px;
		margin: 0 0 30px 0;
    _z-index:0;
	}
	.fixed .formErrorArrow {
		display: none;
	}
	.formError .formErrorArrowBottom {
		margin: -6px;
		top: 0;
	}
	.fixed .formErrorArrowBottom {
		display: none;
	}

		.formError .formErrorArrow div {
			margin: 0 auto;
			display: block;
			height: 1px;
			background: #252525;
			line-height: 0px;
			font-size: 0px;
			box-shadow: 0px 2px 3px #888;
			-moz-box-shadow: 0px 2px 3px #888;
			-webkit-box-shadow: 0px 2px 3px #888;
		}
			.formError .formErrorArrowBottom div{
				box-shadow: none;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
			}

		.formError .formErrorArrow .line10 {
			width: 19px;
		}
		.formError .formErrorArrow .line9 {
			width: 17px;
		}
		.formError .formErrorArrow .line8 {
			width: 15px;
		}
		.formError .formErrorArrow .line7 {
			width: 13px;
		}
		.formError .formErrorArrow .line6 {
			width: 11px;
		}
		.formError .formErrorArrow .line5 {
			width: 9px;
		}
		.formError .formErrorArrow .line4 {
			width: 7px;
		}
		.formError .formErrorArrow .line3 {
			width: 5px;
		}
		.formError .formErrorArrow .line2 {
			width: 3px;
		}
		.formError .formErrorArrow .line1{
			width: 1px;
		}


/* dislay */
.hid_url { display:none;}

// Contact
.contactpage {

	#wrap {
		overflow: hidden;
	}
	&__logo {
		display: block;
		margin: 38px auto 26px;
		width: 223px;
		font-size: 0;
	}
	&__inner {
		max-width: 840px;
		padding-left: 20px;
		padding-right: 20px;
		margin: auto;
	}
	&__boxintro {
		background: #f1f1f1;
		position: relative;
		margin: 85px auto 120px;
		font-size: 16px;
		padding: 58px 245px 58px 57px;

		img {
			position: absolute;
			top: -13px;
    	right: -21px;
		}
		span, strong {
			display: block;
		}
		span {
			font-weight: bold;
			font-size: 22px;
			letter-spacing: 1px;
		}
		strong {
			font-size: 32px;
			letter-spacing: 1.5px;
			margin-bottom: 24px;
		}
		p {
			line-height: 1.65;
			padding-left: 6px;
		}
	}
	&__boxtelhd {
		margin-top: 120px;
	}
	&__hd {
		text-align: center;

		strong {
			display: inline-block;
			font-size: 22px;
			border-bottom: 2px solid #080404;
			padding-bottom: 8px;
			letter-spacing: 1px;
		}
	}
	&__tel {
		text-align: center;
		margin: 24px auto 91px;

		&--main {
			position: relative;
		}
		&--num {
			font-weight: 900;
			background: url(../img/common/icon/ico_tel_big.png) no-repeat center left;
			background-size: 21px 27px;
			padding-left: 30px;
			font-size: 44px;
			display: inline-block;
			line-height: 1;
			letter-spacing: 2px;
			margin-bottom: 10px;
		}
		small {
			display: block;
			letter-spacing: 1.7px;
		}
	}
	.step {
		img {
			display: block;
			margin: auto;
		}
	}
	.step-1 {
		margin: 49px 0 46px;
	}
	.step-2 {
		margin: 85px auto 46px;
	}
	.step-3 {
		margin: 145px auto 103px;
	}
	.tableContact {
		max-width: 780px;
		margin: auto;
		border: 1px solid #080404;

		th,td {
			text-align: left;
			border-bottom: 1px solid #b2b2b2;
			font-size: 16px;
			padding-top: 24px;
			padding-bottom: 21px;
			padding-right: 26px;
			letter-spacing: 0.5px;
			vertical-align: middle;
		}
		tr:last-child {
			th,td {
				border: 0;
			}
		}
		th {
			font-weight: bold;
			width: 244px;
			background: #f1f1f1;
			padding-left: 22px;
			padding-right: 10px;

			&.vtop {
				vertical-align: top;
			}
			em {
				color: #fff;
				background: #d53230;
				font-size: 12px;
				font-weight: normal;
				padding: 5px 12px;
				margin-right: 12px;

				&.unrq {
					background: #808080;
				}
			}
		}
		td {
			padding-left: 26px;

			input, textarea {
				outline: none;
			}
			input[type="text"], input[type="tel"], input[type="email"], input[type="number"] {
				@include border-radius(5px);
				border: 1px solid #a8a8a8;
				background: #f7d6d6;
				width: 100%;
				padding: 8px 15px;
				letter-spacing: -0.5px;
				width: 270px;

				&.unrq, &.pass {
					background: #fff;
				}
			}
			textarea {
				display: block;
				width: 100%;
				@include border-radius(5px);
				border: 1px solid #808080;
				padding: 5px 10px;
    		height: 179px;
				line-height: 1.5;
			}
			.listtype {
				font-size: 0;
				padding-left: 7px;

				li {
					display: inline-block;
					vertical-align: top;
					position: relative;

					&:first-child {
						margin-right: 90px;
					}
					input {
						display: none;
					}
					label {
						font-size: 16px;
				    position: relative;
				    padding-left: 36px;
				    display: block;
						cursor: pointer;

						&::before, &::after {
							content: "";
							position: absolute;
							@include border-radius(50%);
						}
						&::before {
							border: 1px solid #808080;
							width: 22px;
							height: 22px;
							top: 0;
							left: 0;
						}
						&::after {
							width: 14px;
							height: 14px;
							top: 5px;
							left: 5px;
							background: #080404;
							display: none;
						}
						&.active {

							&::after {
								display: block;
							}
						}
					}
				}
			}
			&.addblock {

				span {
					margin-right: 12px;
				}
			}
			#zipcode {
				width: 169px;
			}
			#address {
				display: block;
				margin-top: 15px;
				width: 457px;
			}
			#email {
				width: 362px;
			}
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #949494;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #949494;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #949494;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #949494;
		}
	}
	.formBlock {

		.notetxt {
			max-width: 780px;
			margin: 32px auto 15px;

			small, strong {
				display: block;
			}
			small {
				color: #d53230;
				letter-spacing: -0.4px;
				margin-bottom: 28px;
			}
			strong {
				margin-bottom: 9px;
			}
			p {
				line-height: 1.9;

				a {
					color: #d53230;
					text-decoration: underline;
				}
			}
		}
		.chkform {
			max-width: 780px;
			margin: 0 auto 96px;
			text-align: center;
			font-size: 0;
			background: #f9e0e0;

			&.active {
				background: #e5e5e5;
			}
			label {
				display: block;
				font-size: 16px;
				padding: 24px 0;
				cursor: pointer;
			}
			input, span {
				display: inline-block;
				vertical-align: middle;
			}
			input {
				outline: none;
				width: 22px;
				height: 22px;
				margin-right: 20px;
			}
			&.pass {
				background: #e5e5e5;
			}
		}
		.formbtn {
			outline: none;
			@include border-radius(0);
			border: 0;
			background: #d53230 url(../img/common/icon/ico_ar_right_round_pc.png) no-repeat center right 16px;
			color: #fff;
			width: 323px;
			height: 70px;
			font-weight: bold;
			font-size: 18px;
			display: block;
			margin: 0 auto;
			cursor: pointer;
			@include transition(all .3s);
			line-height: 74px;
			letter-spacing: 1.5px;

			&:hover, &.disabled {
				opacity: 0.5;
			}
			&.disabled:hover {
				cursor: default;
			}
		}
		.mailtxt {
			max-width: 780px;
			margin: 0 auto;
			text-align: center;
			margin: 59px auto 116px;

			a {
				text-decoration: underline;
				color: #d53230;
			}
			@media (min-width:768px) and (max-width:800px) {
				font-size: 13px;
			}
		}
		.gobacktxt {
			text-align: right;
			max-width: 780px;
			margin: 27px auto 108px;

			a {
				display: inline-block;
				text-decoration: underline;
			}
		}
	}
	&__copyright {
		text-align: center;
		font-size: 12px;
		letter-spacing: 0.6px;
		margin-bottom: 60px;
	}
	@media (min-width:768px) and (max-width:799px) {
		.contactpage__boxintro {
			padding-left: 45px;
		}
	}
	@media (max-width:767px) {
		padding: 0;

		&__logo {
			width: 129px;
			margin: 4px auto 3px;
		}
		&__inner {
			padding-left: 15px;
			padding-right: 15px;
		}
		&__hd {

			strong {
				font-size: 16px;
				border: 0;
				padding: 0;
			}
		}
		&__boxintro {
			padding: 0;
			margin: 42px auto 68px;

			&--wrapper {
				position: relative;
				margin: auto;
				max-width: 290px;
				padding: 20px 10px 21px 15px;
			}
			span {
				font-size: 14px;
				line-height: 1.35;
			}
			strong {
				font-size: 19px;
				letter-spacing: 0.5px;
				margin-bottom: 7px;
			}
			p {
				font-size: 12px;
				padding-left: 1px;
			}
			img {
				width: 105px;
				top: -10px;
				right: 5px;
			}
		}
		&__boxtelhd {
			margin-top: 42px;
		}
		&__tel {
			border: 1px solid #080404;
			margin: 10px auto 49px;
			position: relative;
			background: url(../img/common/icon/ico_ar_right_round_black.png) no-repeat top 28px right 10px;
    	background-size: 22px 22px;

			&--main {
				max-width: 288px;
				margin: auto;
				text-align: left;
				padding: 16px 10px 10px 22px;
				// background: url(../img/common/icon/ico_ar_right_round_black.png) no-repeat top 28px right 10px;
				// background-size: 22px 22px;
			}
			&--num {
				background-size: 13px 17px;
				font-size: 26px;
				letter-spacing: 0.3px;
				margin-bottom: 0;
				padding-left: 17px;
				display: block;
			}
			small {
				font-size: 12px;
				letter-spacing: 1px;
				padding-left: 4px;
			}
			p {
				color: #fff;
		    font-size: 12px;
		    background: #080404;
		    text-align: center;
		    padding: 5px;
		    letter-spacing: 0.5px;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
				width: 100%;
				height: 100%;
			}
		}
		.step-1 {
			margin: 14px 0 29px;
		}
		.step-2 {
			margin: 32px auto 35px;
		}
		.step-3 {
			margin: 33px auto 22px;
		}
		.tableContact-wrapper {
			margin-left: -15px;
			margin-right: -15px;
		}
		.tableContact {
			border: 0;
			border-top: 1px solid #989898;

			tr {
				&:first-child {
					th {
						padding-top: 17px;
					}
				}
			}
			th, td {
				background: none;
				display: block;
				width: 100%;
				padding-left: 16px;
				padding-right: 16px;
			}
			th {
				border: 0;
				font-size: 14px;
				padding-top: 23px;
    		padding-bottom: 12px;

				em {
					margin-left: 1px;
				}
			}
			td {
				padding-top: 0;
				font-size: 13px;
				padding-bottom: 22px;

				input[type="text"], input[type="tel"], input[type="email"], input[type="number"], textarea {
					-webkit-appearance: none;
			    -moz-appearance: none;
			    appearance: none;
					box-shadow: none;
				}
				input[type="text"], input[type="tel"], input[type="email"], input[type="number"] {
					width: 100%;
					padding: 0 10px;
					height: 47px;
					letter-spacing: 0;
				}
				textarea {
					height: 132px;
				}
				#address, #email, #tel {
					width: 100%;
				}
				#zipcode {
					width: 214px;
				}
				#address {
					margin-top: 14px;
				}
				&.addblock {

					span {
						font-size: 14px;
						margin: 0 9px 0 8px;
						position: relative;
						top: 2px;
					}
				}
				.listtype {
					padding: 10px 0 14px;

					li {

						&:first-child {
							margin-right: 64px;
						}
						label {
							font-size: 14px;
							line-height: 26px;
							padding-left: 37px;

							&::before {
								width: 24px;
								height: 24px;
							}
							&::after {
								top: 6px;
								left: 6px;
							}
						}
					}
				}
			}
		}
		.formBlock {

			.notetxt {
				margin: -4px auto 19px;

				small {
					font-size: 12px;
					letter-spacing: 0px;
					line-height: 1.5;
					margin-bottom: 14px;
				}
				strong {
					margin-bottom: 4px;
				}
				p {
					line-height: 1.5;
					font-size: 12px;
				}
			}
			.chkform {
				margin: 0 10px 81px;

				input {
					width: 17px;
					height: 17px;
					margin-right: 13px;
				}
				label {
					padding: 12px 0 10px;
					font-size: 14px;

					span {
						letter-spacing: 0.7px;
					}
				}
			}
			.formbtn {
				width: 260px;
				font-size: 15px;
				background-image: url(../img/common/icon/ico_ar_right_round.png);
				background-repeat: no-repeat;
				background-position: center right 7px;
				background-size: 22px 22px;
				height: 60px;
				line-height: 56px;

				span {

				}
			}
			.mailtxt {
				font-size: 12px;
				text-align: left;
				line-height: 1.5;
				margin: 33px -5px 42px;
			}
		}
		.formConfirm {

			.tableContact {

				tr {
					&:last-child {
						td {
							border-bottom: 1px solid #b2b2b2;
						}
					}
				}
				th {
					padding-bottom: 5px;
				}
			}
			.gobacktxt {
				margin: 17px auto 35px;

				a {
					font-size: 13px;
					margin-right: 2px;
				}
			}
		}
		&__copyright {
			font-family: "Roboto", sans-serif;
			margin-bottom: 25px;
		}
		.footer__gotop {
			bottom: 26px;

			&.show {
				right: 20px;
			}
		}
	}
}
.thankspage {

	.contactpage__inner {
		max-width: 865px;
		margin-bottom: 131px;
	}
	&__content {
		font-size: 16px;
		margin-bottom: 119px;
	}
	&__btn {
		text-align: center;
	}
	.top__cmbtn {
		margin: auto;
	}
	@media (max-width: 767px) {

		.contactpage__inner {
			margin-bottom: 100px;
		}
		&__content {
			font-size: 13px;
			margin-bottom: 48px;
			letter-spacing: 0.6px;
		}
	}
}
