@charset "UTF-8";
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import "mixin";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
	content: ".";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
	line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
table img {
	margin: 0;
	padding: 0;
	vertical-align: bottom;
	border: 0;
}
p {
	margin: 0;
	padding: 0;
}
ol, ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
input, textarea {
	margin: 0;
	font-size: 100%;
	resize: none;
}
table {
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 100%;
}
dl, dt, dd, th, td {
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	/*font-size: 100%;*/
	font-weight: normal;
	margin: 0;
	padding: 0;
}
pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
em {
	font-style: normal;
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
	outline: none;
	transition:0.3s;
}
a:link {
	color: $colortext;
	text-decoration: none;
}
a:visited {
	color: $colortext;
	text-decoration: none;
}
a:hover {
	opacity: 0.5;
	text-decoration: none;
}
a:active {
	color: $colortext;
	text-decoration: none;
}

/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
.ffM {font-family: "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", serif;}
.ffR {font-family: 'Roboto', sans-serif;}
.ffY {font-family: 游ゴシック体, "YuGothic", "Noto Sans Japanese", "Hiragino Kaku Gothic Pro", メイリオ, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;}
.ffP {font-family: 'Poppins', sans-serif;}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 0 auto;
}

.alignright {
    float:right;
    margin: 0;
}

.alignleft {
    float: left;
    margin: 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

.contentcms img {
	max-width:100%;
	height:auto;
	max-height:100%;
	width:100%;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
        white-space: nowrap;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/
/* MovableType */
div#pageBoot {
	margin: 35px 0 60px 0;
	text-align: center;
}
div#pageBoot a {
	color: #aaa;
	text-decoration: none !important;
}
span.current_page {
	color: #FFFFFF;
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
}
a.link_page {
	padding: 5px;
	border: 1px solid #aaa !important;
}
a.link_page:hover {
	padding: 5px;
	border: 1px solid #aaa;
	background-color: #aaa;
	color: #FFFFFF !important;
}
/* WordPress */
.wp-pagenavi-wrapper {
	width: 100%;
	display: inline-block;
	text-align: center;
}
.wp-pagenavi {
	margin-top: 126px;
	position: relative;
	padding: 0 45px 44px;
	display: inline-block;
}
.wp-pagenavi span, .wp-pagenavi a {
	display:inline-block;
	vertical-align: top;
	width: 40px;
	height: 40px;
	font-size: 16px;
	line-height: 40px;
	background: #f1f1f1;
}
.wp-pagenavi span.extend {
	line-height: 31px;
}
.wp-pagenavi a:hover, .wp-pagenavi span.current {
	background: #808080;
	color: #fff;
	opacity: 1;
}
.wp-pagenavi .pages {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	text-align: center;
	background: none;
	font-size: 12px;
	letter-spacing: 1px;
}
.wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink {
	position: absolute;
	top: 0;
}
.wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink, .wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover {
	background-color: #080404;
	background-size: 22px 11px;
	background-position: center center;
	background-repeat: no-repeat;
}
.wp-pagenavi .nextpostslink:hover, .wp-pagenavi .previouspostslink:hover {
	opacity: 0.5;
}
.wp-pagenavi .nextpostslink, .wp-pagenavi .nextpostslink:hover {
	background-image: url(../img/column/ico_next.svg);
	right: 0;
}
.wp-pagenavi .previouspostslink, .wp-pagenavi .previouspostslink:hover {
	background-image: url(../img/column/ico_prev.svg);
	left: 0;
}
@media (max-width:767px) {
	.wp-pagenavi {
		padding: 0 41px 43px;
		margin: 31px -15px 0;
	}
	.wp-pagenavi span, .wp-pagenavi a {
		width: 34px;
		font-size: 16px;
	}
	.wp-pagenavi .nextpostslink, .wp-pagenavi .previouspostslink {
		width: 40px;
		height: 40px;
		background-size: 22px 12px;
	}
	.wp-pagenavi .pages {
		letter-spacing: 0.5px;
	}
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
body {
	width: 100%;
	color: $colortext;
	margin: 0;
	padding: 0;
	line-height: 1.6;
	letter-spacing: 0;
	// font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Osaka", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-family: 游ゴシック体, "YuGothic", "Noto Sans Japanese", "Hiragino Kaku Gothic Pro", メイリオ, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	font-size: 14px;
	height: 100%;
	word-break: break-word;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media (max-width:767px) {
	body {
		padding-top: 50px;
	}
}
.hidden {
	display: none !important;
}

/*-----------------------------------------------------------
 Container/Maincontent
------------------------------------------------------------*/
div.container {
	width: $widthcm;
	height: auto;
	padding: 0 20px;
	margin: auto;
}
div.mainContent {
	width: 700px;
	height: auto;
	margin: 0;
	float: right;
}

/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.sp {

	@include min-screen(768px) {
		display: none!important;
	}
}

.pc {
	@include max-screen(767px) {
		display: none!important;
	}
}
img {
	max-width:100%;
	height:auto;
}
img {
	max-width:100%;
}
.wcm {
	width: 100%;
	max-width: $widthcm;
	margin-left:auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
}

// mainImg
.mainImg {
	width: 100%;
	height: 124px;
	background: url(../img/common/header/bg_mainimg.jpg) no-repeat top center;
	background-size: cover;
	display: table;
	text-align: center;

	&__content {
		display: table-cell;
		vertical-align: middle;
		font-weight: bold;
		font-size: 28px;
		color: #fff;
		letter-spacing: -1px;
	}
	@media (max-width: 767px) {
		height: 60px;
		background: url(../img/common/header/bg_mainimg_sp.jpg) no-repeat top center;
		background-size: cover;

		&__content {
			font-size: 19px;
		}
	}
}

// Breadcrumbs
.breadcrumbs {
	font-size: 0;
	padding-top: 17px;
	padding-bottom: 17px;

	li {
		display: inline;
		font-size: 12px;
		margin-right: 12px;
		position: relative;
		padding-right: 21px;

		&::before {
			content: "";
			background: url(../img/common/icon/ico_deliv.svg) no-repeat center center;
			background-size: 100% 100%;
			width: 7px;
			height: 7px;
			position: absolute;
			top: 50%;
			margin-top: -3px;
			right: 0;
		}
		a {
			position: relative;
			padding-bottom: 1px;

			&::before {
				content: "";
				width: 100%;
				height: 1px;
				background: #080404;
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		&:first-child {
			letter-spacing: 0.5px;
		}
		&:last-child {
			margin-right: 0;
			padding-right: 0;

			&::before {
				display: none;
			}
		}
	}
	@media (max-width:767px) {
		padding: 11px 15px;

		li {

			font-size: 10px;
			padding-right: 18px;
			margin-right: 10px;

			&::before {
				width: 6px;
				height: 6px;
    		right: 1px;
			}
			&:first-child {
				letter-spacing: 0.4px;
			}
		}
	}
}

.ui-datepicker .ui-datepicker-next, .ui-datepicker .ui-datepicker-prev {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	transition: opacity .3s;
}

.errorpage {

	&__content {
		padding: 50px 20px 0;
		margin-bottom: 100px;
		text-align: center;

		p {
			font-size: 16px;
			margin-bottom: 40px;
		}
	}
	@media (max-width:767px) {
		&__content {
			padding: 30px 15px 0;
			margin-bottom: 70px;

			p {
				text-align: justify;
				font-size: 13px;
				margin-bottom: 30px;
			}
		}
	}
}
